import React from "react";
import BottomTabs from "../../layout/BottomTabs";
import Layout from "../../layout/Layout";
import useProjectLexiconName from "../../queries/ProjectLexiconName";
import ProjectOverview from "../../project/ProjectInfo";
import Samples from "../../../Session/Samples";

interface SampleListPageProps {
  projectId: string;
}

export default function SampleListPage(p: SampleListPageProps) {
  const { data: lexiconInfo } = useProjectLexiconName(p.projectId);
  const lexiconId = lexiconInfo?.project_by_pk?.lexicon?.id;

  return (
    <Layout overview={<ProjectOverview projectId={p.projectId}/>} footer={<BottomTabs projectId={p.projectId} />}>
      {lexiconId ? (
        <Samples sessionId={p.projectId}/>
      ) : null}
    </Layout>
  );
}
