import { ApolloError } from "@apollo/client";

export interface ApolloLoadable<T> {
  loading: boolean;
  data?: T;
  error?: ApolloError
}


export function mapApollo<T, R>(loadable: ApolloLoadable<T>, mapper: (data: T) => R) {
  return {...loadable, data: loadable.data == null ? undefined : mapper(loadable.data)}
}