import React from "react";
import OldDescriptorDetails from '../../Session/DescriptorDetails'

interface DescriptorDetailsPros {
  descriptorId: string;
}

const DescriptorDetails = (p: DescriptorDetailsPros) => {
  return <OldDescriptorDetails descriptorId={p.descriptorId}/>
}

export default DescriptorDetails