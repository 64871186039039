import {
  Box,
  StandardTextFieldProps,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";

interface NumericTextInputProps extends StandardTextFieldProps {
  value: number | null;
  onChangeInt: (newValue: number | null) => void;
}

function NumericTextInput(p: NumericTextInputProps) {
  const { value, onChangeInt, ...rest } = p;
  const handleChange = (newValue: string) => {
    let asInteger = parseInt(newValue);
    if (!isNaN(asInteger)) {
      if (asInteger > 1000) {
        asInteger = 1000;
      } else if (asInteger < 0) {
        asInteger = 0;
      }
      onChangeInt(asInteger);
    } else {
      onChangeInt(null);
    }
  };
  return (
    <TextField
      value={value == null ? "" : `${value}`}
      onChange={(e) => handleChange(e.target.value)}
      {...rest}
    />
  );
}

export interface RatingOptionsProps {
  min: number | null;
  max: number | null;
  onMinChange: (newMin: number | null) => void;
  onMaxChange: (newMax: number | null) => void;
}

export default function RatingOptions(p: RatingOptionsProps) {
  return (
    <React.Fragment>
      <Typography variant="subtitle1">Rating Options</Typography>
      <Box display="flex" flexDirection="row">
        <Box marginRight={1}>
          <NumericTextInput
            label="Minimum"
            value={p.min}
            onChangeInt={p.onMinChange}
          />
        </Box>
        <Box>
          <NumericTextInput
            label="Maximum"
            value={p.max}
            onChangeInt={p.onMaxChange}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
}
