import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const getProjectMixes = gql`
query GetProjectMixes($id: uuid!) {
  project_by_pk(id: $id) {
    mixes(order_by: {name: asc}) {
      id
      name
      description
      image
    }
  }
}

`;

export interface GetProjectMixesVariables {
  id: string;
}

export interface GetProjectMixesData {
  project_by_pk?: {
    mixes: {
      id: string;
      name: string;
      description?: string;
      image?: string;
    }[]
  };
}

export function useProjectMixes(
  options?:
    | QueryHookOptions<
        GetProjectMixesData,
        GetProjectMixesVariables
      >
    | undefined
) {
  return useQuery<
    GetProjectMixesData,
    GetProjectMixesVariables
  >(getProjectMixes, options);
}
