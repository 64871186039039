import React from "react";
import { css } from "@emotion/react";
import { Typography } from "@material-ui/core";

const styles = {
  container: css`
    background-color: white;
    color: black;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 0px 0px;
    grid-template-areas:
      "header header"
      "image color";
    padding: 10px;
  `,
  heading: css`
    grid-area: header;
  `,
  associationContainer: css`
    flex-direction: column;
    display: flex;
    justify-items: center;
    align-items: center;
  `,
  imageBlot: css`
    width: 35vw;
    height: 35vw;
    border: 1px solid black;
    border-radius: 10px;
    box-sizing: border-box;
    background-position: center;
    background-size: cover;
  `,
  colorBlot: css`
    width: 35vw;
    height: 35vw;
    border: 1px solid black;
    border-radius: 10px;
    box-sizing: border-box;
  `,
};

interface AssociationsProps {
  image?: string;
  color?: string;
  colorName?: string;
}

export default function Associations(p: AssociationsProps) {
  const imageStyle = css`
    background-image: url(${p.image || ""});
  `;
  const colorStyle = css`
    background-color: ${p.color || ""};
  `;
  return (
    <div css={styles.container}>
      <Typography css={styles.heading} align="center" variant="h6">
        Associations
      </Typography>
      {p.image ? (
        <div css={styles.associationContainer}>
          <Typography align="center" variant="subtitle1">
            Image
          </Typography>
          <div css={[styles.imageBlot, imageStyle]}></div>
        </div>
      ) : null}
      {p.color && p.colorName ? (
        <div css={styles.associationContainer}>
          <Typography align="center" variant="subtitle1">
            Color
          </Typography>
          <div css={[styles.colorBlot, colorStyle]}/>
          <Typography>{p.colorName}</Typography>
        </div>
      ) : null}
    </div>
  );
}
