import * as core from "@material-ui/core";
import React, { Fragment, useRef, useState } from "react";
import { range } from "lodash";
import { css } from "@emotion/react";
import { Skeleton } from "@material-ui/lab";
import {
  GetSessionChannelsData,
  useSessionChannels,
} from "../../../queries/GetSessionChannels";
import ApolloRouter from "../../../../util/ApolloRouter";
import { useEffect } from "react";
import { useIsMounted } from "../../../../pages/mobile/components/Scanner/useIsMounted";
import { useUpdateChannelMetadata } from "../../../queries/UpdateChannelMetadata";
import { useChannelInfo } from "../../../queries/GetChannel";
import ImageUpload from "../../components/ImageUpload";
import LoadableImage from "../../components/LoadableImage";
import { ChannelFeedback } from "./ChannelFeedback";

const CHANNEL_COUNT = 6;
export const channelDefaultBackgrounds = [
  "http://res.cloudinary.com/est-analytical/image/upload/v1626983432/xx3efx08jnrrxx3snbj4.png",
  "http://res.cloudinary.com/est-analytical/image/upload/v1626983453/mxtanglu4m1vgjidpcsb.png",
];

export interface ChannelsTabProps {
  sessionId: string;
}

const styles = {
  container: css`
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: 100%;
    width: 100%;
    height: calc(100vh - 160px);
    margin-right: 10px;
  `,
};

export function ChannelsTab(p: ChannelsTabProps) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const query = useSessionChannels({
    variables: { id: p.sessionId },
    pollInterval: 1000,
  });

  console.log(query.data);

  console.log("channels tab render");

  return (
    <div css={styles.container}>
      <ChannelSelector
        sessionId={p.sessionId}
        onChannelSelected={setSelectedIndex}
        selectedIndex={selectedIndex}
      />
      <core.Box padding={2} overflow="scroll" component={core.Card}>
        <ChannelDetails sessionId={p.sessionId} channelIndex={selectedIndex} />
      </core.Box>
    </div>
  );
}

const channelSelectorStyles = {
  container: css`
    display: flex;
    flex-direction: column;
    margin-right: 10px;
  `,
  channel: css`
    flex: 1 1 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 2px;
    margin-top: 2px;
    // background-color: white;
  `,
  channelText: css`
    text-align: center;
    font-size: 1.5em !important;
    text-overflow: ellipsis;
    color: white;
    text-shadow: 2px 2px 2px black;
  `,
};

function ChannelDetails(p: { sessionId: string; channelIndex: number }) {
  const channelInfo = useChannelInfo({
    fetchPolicy: "no-cache",
    variables: { project_id: p.sessionId, index: p.channelIndex },
  });

  return (
    <ApolloRouter
      loadable={channelInfo}
      loading={() => <div></div>}
      ready={(data) =>
        data.project_channel?.length === 1 ? (
          <Fragment>
            <ChannelDetailsHydrated
              key={p.channelIndex}
              {...data.project_channel[0]}
            />
          </Fragment>
        ) : (
          <div>Error loading channel {p.channelIndex + 1}</div>
        )
      }
    />
  );
}

function ChannelDetailsHydrated(p: {
  id: string;
  name: string;
  channel_index: number;
  description: string;
  image?: string;
  development_code: string | null;
  group_code: string | null;
  solvent: string | null;
  concentration: string | null;
  notes: string | null;
}) {
  const [activeIndex, setActiveIndex] = useState(p.channel_index);
  const [dirty, setDirty] = useState(false);
  const [name, setName] = useState(p.name);
  const [developmentCode, setDevelopmentCode] = useState(p.development_code || "");
  const [groupCode, setGroupCode] = useState(p.group_code || "");
  const [solvent, setSolvent] = useState(p.solvent || "");
  const [concentration, setConcentration] = useState(p.concentration || "");
  const [notes, setNotes] = useState(p.notes || "");



  const firstUpdate = useRef(true);
  const [update] = useUpdateChannelMetadata();

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (p.channel_index !== activeIndex) {
      console.log("new index");
      setActiveIndex(p.channel_index);
      setName(p.name);
      setDevelopmentCode(p.development_code || "");
      setGroupCode(p.group_code || "");
      setSolvent(p.solvent || "");
      setConcentration(p.concentration || "");
      setNotes(p.notes || "");

      // Prevent this effect from being called as a result of changing the state above
      firstUpdate.current = true;
      return;
    }

    setDirty(true);
    const saveTimeout = setTimeout(() => {
      console.log("SAVING TO DATABASE");
      update({
        variables: {
          id: p.id,
          name: name,
          development_code: developmentCode,
          group_code: groupCode,
          concentration: concentration,
          solvent: solvent,
          notes: notes
        },
      }).then(() => {
        setDirty(false);
      });
    }, 500);
    return () => {
      clearTimeout(saveTimeout);
    };
  }, [p.channel_index, name, developmentCode, groupCode, concentration, solvent, notes]);

  return (
      <core.Box display="flex" flexDirection="column" overflow="scroll">
        <core.Typography
          variant="h4"
          align="center"
          style={{ color: dirty ? "gray" : "black" }}
        >
          Channel {activeIndex + 1}
        </core.Typography>
        <core.Box display="grid" gridTemplateColumns="1fr" gridTemplateRows="auto" gridGap="1em 1em">
          <core.Box>
            <core.TextField
              value={name}
              label="Channel name"
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
          </core.Box>
          <core.Box>
            <core.TextField
            InputLabelProps={{ shrink: true }}
              value={developmentCode}
              label="Development code"
              onChange={(e) => setDevelopmentCode(e.target.value)}
              fullWidth
            />
          </core.Box>
          <core.Box>
            <core.TextField
            InputLabelProps={{ shrink: true }}
              value={groupCode}
              label="Group code"
              onChange={(e) => setGroupCode(e.target.value)}
              fullWidth
            />
          </core.Box>
          <core.Box>
            <core.TextField
            InputLabelProps={{ shrink: true }}
              value={solvent}
              label="Solvent"
              onChange={(e) => setSolvent(e.target.value)}
              fullWidth
            />
          </core.Box>
          <core.Box>
            <core.TextField
            InputLabelProps={{ shrink: true }}
              value={concentration}
              label="Concentration"
              onChange={(e) => setConcentration(e.target.value)}
              fullWidth
            />
          </core.Box>
          <core.Box>
            <core.TextField
            InputLabelProps={{ shrink: true }}
              value={notes}
              label="Notes"
              onChange={(e) => setNotes(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </core.Box>
        </core.Box>
        <core.Box>

        </core.Box>
      </core.Box>
  );
}



function ChannelSelector(p: {
  sessionId: string;
  selectedIndex?: number;
  onChannelSelected(channelIndex: number): void;
}) {
  const query = useSessionChannels({
    variables: { id: p.sessionId },
    pollInterval: 1000,
  });

  return (
    <div css={channelSelectorStyles.container}>
      {query.data?.project_by_pk
        ? query.data.project_by_pk.channels.map((channel) => { 
          const isSelected = p.selectedIndex === channel.channel_index;
          const bgImage = channel.image || channelDefaultBackgrounds[channel.channel_index % 2];
          const background = isSelected ? `center/cover url(${bgImage})` : `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), center/cover url(${bgImage})`;
          return(
            <core.Card
              css={channelSelectorStyles.channel}
              style={{
                background: background,
                // backgroundImage: `url(${
                //   channel.image ||
                //   channelDefaultBackgrounds[channel.channel_index % 2]
                // })`,
                zIndex: p.selectedIndex === channel.channel_index ? 999 : 0,
              }}
              elevation={p.selectedIndex === channel.channel_index ? 5 : 1}
              onClick={() => p.onChannelSelected(channel.channel_index)}
            >
              <span css={channelSelectorStyles.channelText}>
                {channel.channel_index + 1}. {channel.name}
              </span>
            </core.Card>
          )})
        : range(6).map((index) => (
            <div css={channelSelectorStyles.channel}>...</div>
          ))}
    </div>
  );
}
