import { css } from "@emotion/react";
import React from "react";

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #dadce0;
    background-color: #fff;
    word-wrap: break-word;
  `,
  stripe: css`
    background-color: rgb(0, 38, 95);
    color: rgba(0, 38, 95, 1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 10px;
    left: -1px;
    top: -1px;
    width: calc(100% + 2px);
  `,
  error: css`
    border: 1px solid #ff2222 !important;
  `,
};

export interface SurveyCardProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  topStripe?: boolean;
  hasError?: boolean;
}

export default function SurveyCard(p: SurveyCardProps) {
  const { children, topStripe, hasError, ...rest } = p;
  return (
    <div css={[styles.container, hasError ? styles.error : null]} {...rest}>
      {topStripe ? <div css={styles.stripe} /> : null}
      {children}
    </div>
  );
}
