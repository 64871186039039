import { gql } from "@apollo/client";

export const PrintCustomLabelsMutation = gql`
mutation PrintCustomLabels($labels: [Label]!) {
  print_custom_labels(labels: $labels) {
    url
  }
}
`;

export interface PrintCustomLabelsVariables {
  labels: {
    qrCode: string;
    caption: string;
    row: number;
    column: number;
  }[]
}

export interface PrintCustomLabelsData {
  print_custom_labels: {
    url: string;
  }
}