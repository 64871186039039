import React from "react";
import useLexiconDetails from "../queries/LexiconDetails";
import Lexicon from "./Lexicon";
import { Typography } from "@material-ui/core";

interface LexiconHOCProps {
  lexiconId: string;
  onDescriptorSelected: (descriptorId: string) => void;
}

const LexiconHOC = (p: LexiconHOCProps) => {
  const {data, error} = useLexiconDetails({variables: {lexicon_id: p.lexiconId}});
  if (error) {
    return <Typography align="center" variant="caption">Error: {error.message}</Typography>
  }
  if (data) {
    const lexicon = {
      id: p.lexiconId,
      name: data.lexicon_by_pk.name,
      description: data.lexicon_by_pk.description,
      image: data.lexicon_by_pk.image,
      descriptors: data.lexicon_by_pk.descriptors.map(d => ({
        id: d.id,
        name: d.name,
        description: d.description,
        image: d.image
      }))
    }
    return <Lexicon lexicon={lexicon} onDescriptorSelected={p.onDescriptorSelected}/>
  }
  return <Typography align="center" variant="caption">Loading...</Typography>
}

export default LexiconHOC