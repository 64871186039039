import React from "react";
import { css } from "@emotion/react";

const ratingColors = [
  css`
    background-color: rgb(125, 125, 125);
  `,
  css`
    background-color: rgb(241, 247, 214);
  `,
  css`
    background-color: rgb(180, 212, 71);
  `,
  css`
    background-color: rgb(91, 128, 41);
  `,
];

const styles = {
  container: css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    max-width: 150px;
    min-height: 50px;
    user-select: none;
  `,
};

interface DescriptorRatingProps {
  name: string;
  rating: number;
  onRatingChange: (newRating: number, currentRating: number) => void;
}

export default function DescriptorRating(p: DescriptorRatingProps) {
  const colorClass = ratingColors[p.rating];

  const handleClick = () => {
    const nextRating = (p.rating + 1) % 4;
    p.onRatingChange(nextRating, p.rating);
  };

  return (
    <div css={[styles.container, colorClass]} onClick={handleClick}>
      {p.name}
    </div>
  );
}
