import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  CardContent,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { useIsMount } from "../../../../util/useIsMount";
import Options, { QuestionOption } from "./Options";
import QuestionTypeSelect from "./QuestionTypeSelect";
import RatingOptionsNumeric from "./RatingOptionsNumeric";
import SurveyCard from "../../../../components/SurveyCard";
import { useDebouncedEffect } from "../../../../util/useDebouncedEffect";
import SelectionOptions from "./SelectionOptions";

export interface QuestionUpdate {
  id: string;
  type: string;
  name: string;
  description: string;
  required: boolean;
  minValue: number | null;
  maxValue: number | null;
  validationMode: string | null;
  validationNumber: number | null;
  validationErrorMessage: string | null;
}

export interface QuestionCardDataProps {
  id: string;
  index: number;
  isFirst: boolean;
  isLast: boolean;
  name: string;
  description: string;
  type: string;
  required: boolean;
  minValue: number | null;
  maxValue: number | null;
  validationMode: string | null;
  validationNumber: number | null;
  validationErrorMessage: string | null;
  options: QuestionOption[];
}

export interface QuestionCardProps extends QuestionCardDataProps {
  onQuestionUpdated: (update: QuestionUpdate) => void;
  onQuestionDeleted: () => void;
  onQuestionMoved: (direction: "up" | "down") => void;
  onOptionCreated: (option: QuestionOption) => void;
  onOptionUpdated: (option: QuestionOption) => void;
  onOptionDeleted: (optionId: string) => void;
}

export default function QuestionCard(p: QuestionCardProps) {
  const isFirstRender = useIsMount();
  const [name, setName] = useState(p.name);
  const [description, setDescription] = useState(p.description);
  const [type, setType] = useState(p.type);
  const [required, setRequired] = useState(p.required);
  const [ratingMin, setRatingMin] = useState<number | null>(p.minValue);
  const [ratingMax, setRatingMax] = useState<number | null>(p.maxValue);
  const [validationMode, setValidationMode] = useState<string | null>(
    p.validationMode
  );
  const [validationNumber, setValidationCount] = useState<number | null>(
    p.validationNumber
  );
  const [validationErrorMessage, setValidationErrorMessage] = useState<string | null>(p.validationErrorMessage);

  const displaySelectOptions = type.startsWith("select_");
  const displayValidationOptions =
    type.startsWith("select_color") || type.startsWith("select_checkbox");
  const displayRatingOptions = type.startsWith("rating_");

  useDebouncedEffect(
    () => {
      if (!isFirstRender) {
        console.log(`changed detected on question: ${name}`);
        p.onQuestionUpdated({
          id: p.id,
          name: name,
          description: description,
          type: type,
          required: required,
          minValue: ratingMin,
          maxValue: ratingMax,
          validationMode: validationMode,
          validationNumber: validationNumber,
          validationErrorMessage: validationErrorMessage
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    100,
    [
      name,
      description,
      type,
      required,
      ratingMin,
      ratingMax,
      validationMode,
      validationNumber,
      validationErrorMessage
    ]
  );

  return (
    <SurveyCard>
      <CardContent>
        <Box display="flex" flexDirection="row">
          <Box flexGrow={2} marginRight={1}>
            <TextField
              fullWidth
              label={`Question ${p.index + 1}`}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
          <Box marginLeft={1} width={"200px"}>
            <QuestionTypeSelect value={type} onChange={setType} />
          </Box>
        </Box>
        <TextField
          fullWidth
          label="Description"
          multiline
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {displaySelectOptions ? (
          <Box marginTop={2}>
            <Options
              type={type}
              options={p.options}
              onOptionCreated={p.onOptionCreated}
              onOptionDeleted={p.onOptionDeleted}
              onOptionUpdated={p.onOptionUpdated}
            />
          </Box>
        ) : null}
        {displayValidationOptions ? (
          <Box marginTop={2}>
            <SelectionOptions
              validationMode={validationMode}
              validationCount={validationNumber}
              validationErrorMessage={validationErrorMessage}
              onValidationModeChange={(mode) => setValidationMode(mode)}
              onValidationCountChange={(count) => setValidationCount(count)}
              onValidationErrorMessageChange={(message) => setValidationErrorMessage(message)}
            />
          </Box>
        ) : null}
        {displayRatingOptions ? (
          <Box marginTop={2}>
            <RatingOptionsNumeric
              min={ratingMin}
              max={ratingMax}
              onMinChange={setRatingMin}
              onMaxChange={setRatingMax}
            />
          </Box>
        ) : null}
        <Box paddingTop={2}>
          <Divider />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button disabled={p.isFirst} onClick={() => p.onQuestionMoved("up")}>
            move up
          </Button>
          <Button disabled={p.isLast} onClick={() => p.onQuestionMoved("down")}>
            move down
          </Button>
          <IconButton onClick={p.onQuestionDeleted} aria-label="delete">
            <FontAwesomeIcon icon={faTrash} fixedWidth />
          </IconButton>
          <FormControlLabel
            control={
              <Switch
                checked={required}
                onChange={(e, checked) => {
                  setRequired(checked);
                }}
                name="checkedB"
                color="primary"
              />
            }
            label="Required"
            labelPlacement="start"
          />
        </Box>
      </CardContent>
    </SurveyCard>
  );
}
