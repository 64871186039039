import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import { range } from "lodash";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useUserId } from "../../../SessionContext";
import { SessionType, SessionTypePicker } from "./components/SessionTypePicker";
import { useCreateNewProject } from "./queries/CreateProject";

export interface NewSessionProps {
}

export function NewSession() {
  const [createNewProject] = useCreateNewProject();
  const [isBusy, setIsBusy] = useState(false);
  const history = useHistory();
  const userId = useUserId();
  console.log(`userId: ${userId}`)

  const handleSubmit = (
    initialName: string,
    initialSessionType: SessionType
  ) => {
    setIsBusy(true);
    console.log(`Beginning to create new session... initialName=${initialName} initialSessionType=${initialSessionType}`)


    console.log('Calling mutation')
    createNewProject({variables: {
      name: initialName,
      session_type: initialSessionType || undefined,
      channels: range(8).map(index => ({
        name: `Channel ${index + 1}`,
        channel_index: index,
      })),
      owner_id: userId!
    }}).then((resp) => {
      if (resp.data) {
        history.push(`/admin/sessions/${resp.data.insert_project_one.id}`)
      }
    }).catch(e => {
      setIsBusy(false);
      console.log('Failed to create session');
      console.log(e);
    })

  };

  return (
    <Box display="flex" justifyContent="center">
      <NewSessionForm onSubmit={handleSubmit} isBusy={isBusy}/>
    </Box>
  );
}

interface NewSessionFormProps {
  isBusy?: boolean;
  initialName?: string;
  initialSessionType?: SessionType;

  onSubmit: (sessionName: string, sessionType: SessionType) => void;
}

function NewSessionForm(p: NewSessionFormProps) {
  const [sessionName, setSessionName] = useState(p.initialName || "");
  const [sessionType, setSessionType] = useState<SessionType>(
    p.initialSessionType || null
  );

  const isBusy = p.isBusy || false;
  const maySubmit = !isBusy && sessionName.length > 0;

  const handleSubmit = () => {
    p.onSubmit(sessionName, sessionType);
  };
  return (
    <Box component={Card} width="fit-content">
      <CardContent>
        <Typography variant="h4">
          Create Session
        </Typography>
        <Box marginTop="10px">
          <TextField
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            label="Session name (Required)"
            value={sessionName}
            onChange={(e) => setSessionName(e.target.value)}
            disabled={isBusy}
          />
        </Box>
        <Box marginTop="10px">
          <Typography>Session Type</Typography>
          <SessionTypePicker
            sessionType={sessionType}
            onChange={setSessionType}
          />
        </Box>
      </CardContent>
      <CardActions style={{ justifyContent: "flex-end" }}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={!maySubmit}
        >
          Create Session
        </Button>
      </CardActions>
    </Box>
  );
}
