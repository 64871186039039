import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { ChromePicker } from "react-color";

export interface ColorPickerProps {
  color: string;
  onColorChange: (newColor: string) => void;
  onClose: () => void;
}

export default function ColorPicker(p: ColorPickerProps) {
  const [color, setColor] = useState(p.color);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => {
      if (wrapperRef.current == null) {
        return;
      }
      if (e.target instanceof Node && wrapperRef.current.contains(e.target)) {
        // Click occurred inside color picker
        return;
      }

      // Click must have occurred outside
      p.onClose();
    };

    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [p]);

  return (
    <div ref={wrapperRef}>
      <ChromePicker
        disableAlpha
        color={color}
        onChange={(result) => setColor(result.hex)}
        onChangeComplete={(result) => {
          setColor(result.hex);
          p.onColorChange(result.hex);
        }}
      />
    </div>
  );
}
