import React from "react";
import { css } from "@emotion/react";
import Layout from "../../layout/Layout";
import ProjectOverview from "../../project/ProjectInfo";
import BottomTabs from "../../layout/BottomTabs";
import {
  getSummaryTableData,
  useProjectSummaryTable,
} from "../../../../components/ProjectSummaryTable/query";
import { mapApollo } from "../../../../util/ApolloLoadable";
import { ProjectSummaryTable } from "../../../../components/ProjectSummaryTable/ProjectSummaryTable";
import ApolloRouter from "../../../../util/ApolloRouter";
import { Box, Typography } from "@material-ui/core";


interface ProjectReportPageProps {
  projectId: string;
}

export function ProjectReportPage(p: ProjectReportPageProps) {
  const tableQuery = useProjectSummaryTable({
    variables: { project_id: p.projectId },
  });

  return (
    <Layout
      overview={<ProjectOverview projectId={p.projectId} />}
      footer={<BottomTabs projectId={p.projectId} />}
    >
      <Box
        padding="5px"
        css={css`
          color: white;
        `}
      >
        <Typography align="center" variant="h5">
          Results Summary
        </Typography>
      </Box>
      <div
        css={css`
          width: 100vh;
          padding: 10px;
          overflow: scroll;
          color: white;
        `}
      >
        <ApolloRouter
          loadable={mapApollo(tableQuery, getSummaryTableData)}
          ready={(data) => tableQuery.data?.project_by_pk?.share_with_evaluators ? <ProjectSummaryTable {...data} /> : <div>Results are not yet shared.</div>}
        />
      </div>
    </Layout>
  );
}
