import { Button } from "@material-ui/core";
import React from "react";
import { useSurveyResponseCsvQuery } from "../queries/SurveyResponseCsv";
import triggerDownload from "js-file-download";

export interface DownloadCsvProps {
  fileName: string;
  surveyId: string;
}

export default function DownloadCsv(p: DownloadCsvProps) {
  const query = useSurveyResponseCsvQuery({
    variables: { survey_id: p.surveyId },
    fetchPolicy: "no-cache",
  });

  const handleDownloadClick = () => {
    triggerDownload(
      query.data?.SUR_survey_by_pk.response_csv.csv!,
      p.fileName,
      "text/csv"
    );
  };

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={query.data?.SUR_survey_by_pk?.response_csv?.csv == null}
      onClick={handleDownloadClick}
    >
      Download CSV
    </Button>
  );
}
