import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  MenuItem,
  Select,
  StandardTextFieldProps,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";

const instructions = "These validations are only enforced if the user makes at least one selection. To enforce them all the time, mark this question as required.";

interface NumericTextInputProps extends StandardTextFieldProps {
  value: number | null;
  maximum?: number;
  onChangeInt: (newValue: number | null) => void;
}

function NumericTextInput(p: NumericTextInputProps) {
  const { value, maximum, onChangeInt, ...rest } = p;
  let m = maximum == null ? 1000 : maximum;
  const handleChange = (newValue: string) => {
    let asInteger = parseInt(newValue);
    if (!isNaN(asInteger)) {
      if (asInteger > m) {
        asInteger = m;
      } else if (asInteger < 0) {
        asInteger = 0;
      }
      onChangeInt(asInteger);
    } else {
      onChangeInt(null);
    }
  };
  return (
    <TextField
      value={value == null ? "" : `${value}`}
      onChange={(e) => handleChange(e.target.value)}
      {...rest}
    />
  );
}

export interface SelectionOptionsProps {
  validationMode: string | null;
  validationCount: number | null;
  validationErrorMessage: string | null;
  onValidationModeChange: (newMode: string | null) => void;
  onValidationCountChange: (newCount: number | null) => void;
  onValidationErrorMessageChange: (newMessage: string | null) => void;
}

export default function SelectionOptions(p: SelectionOptionsProps) {
  const validationMode = p.validationMode || "any";
  const hideCountInput = validationMode === "any";
  return (
    <React.Fragment>
      <Typography variant="subtitle1">
        Answer Validation
        <Tooltip title={instructions} >
          <span> <FontAwesomeIcon icon={faQuestionCircle} /></span>
        </Tooltip>
      </Typography>
      <Box display="flex" flexDirection="row">
        <Box>
          <Select
            value={validationMode}
            onChange={(e) => p.onValidationModeChange(e.target.value as string)}
            variant="outlined"
          >
            <MenuItem value="any">Select any</MenuItem>
            <MenuItem value="at_least">Select at least</MenuItem>
            <MenuItem value="at_most">Select at most</MenuItem>
            <MenuItem value="exactly">Select exactly</MenuItem>
          </Select>
        </Box>
        <Box marginLeft={1}>
          <NumericTextInput
            disabled={hideCountInput}
            label="Answer count"
            value={p.validationCount}
            onChangeInt={p.onValidationCountChange}
          />
        </Box>
        <Box marginLeft={1} flexGrow={1}>
          <TextField
            fullWidth
            disabled={hideCountInput}
            label="Custom error message (optional)"
            value={p.validationErrorMessage}
            onChange={(e) => p.onValidationErrorMessageChange(e.target.value)}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
}
