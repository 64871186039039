import { Box, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import EditSurvey from "./tabs/EditSurvey";
import SurveyResults from "./tabs/SurveyResults";

type TabPages = "edit" | "results";

export interface ManageSurveyPageProps {
  surveyId: string;
}

export default function ManageSurveyPage(p: ManageSurveyPageProps) {
  const [tab, setTab] = useState<TabPages>("edit");
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e,v) => setTab(v)}
        aria-label="disabled tabs example"
      >
        <Tab label="Edit" value={"edit"} />
        <Tab label="Results" value={"results"} />
      </Tabs>
      {tab === 'edit' ? <EditSurvey surveyId={p.surveyId} /> : <SurveyResults surveyId={p.surveyId}/>}
    </Box>
  );
}
