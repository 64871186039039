import React from "react";
import QInputProps from "./QInputProps";
import QRatingHeart from "./QRatingHeart";
import QRatingSlider from "./QRatingSlider";
import QRatingStar from "./QRatingStar";
import QSelectCheckbox from "./QSelectCheckbox";
import QSelectColor from "./QSelectColor";
import QSelectDropdown from "./QSelectDropdown";
import QSelectRadio from "./QSelectRadio";
import QTextParagraph from "./QTextParagraph";
import QTextShort from "./QTextShort";

export interface QMultiInputProps extends QInputProps {
  type: string;
}

export default function QMultiInput(p: QMultiInputProps) {
  const { type, ...rest } = p;
  switch (type) {
    case "text_short":
      return <QTextShort {...rest} />;
    case "text_paragraph":
      return <QTextParagraph {...rest} />;
    case "select_radio":
      return <QSelectRadio {...rest} />;
    case "select_checkbox":
      return <QSelectCheckbox {...rest} />;
    case "select_dropdown":
      return <QSelectDropdown {...rest} />;
    case "select_color":
      return <QSelectColor {...rest} />;
    case "rating_heart":
      return <QRatingHeart {...rest} />;
    case "rating_star":
      return <QRatingStar {...rest} />;
    case "rating_slider":
      return <QRatingSlider {...rest} />;
    default:
      return <div>Unknown type: {p.type}</div>;
  }
}
