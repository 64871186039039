import React from "react";
import Layout from "../../layout/Layout";
import BottomTabs from "../../layout/BottomTabs";
import DescriptorDetails from "../../descriptor/DescriptorDetails";

interface DescriptorPageProps {
  descriptorId: string;
}

const DescriptorPage = (p: DescriptorPageProps) => {
  return (
    <Layout
      footer={<BottomTabs />}
    >
      <DescriptorDetails descriptorId={p.descriptorId} />
    </Layout>
  );
};

export default DescriptorPage;
