import { gql, MutationHookOptions, useMutation } from "@apollo/client";

const EvaluateSampleDescriptor = gql`
mutation EvaluateSampleDescriptor($sample_id: uuid!, $app_user_id: uuid!, $descriptor_id: uuid!, $rating: Int!) {
  insert_evaluation_descriptor_one(object: {evaluation: {data: {app_user_id: $app_user_id, sample_id: $sample_id}, on_conflict: {constraint: evaluation_app_user_id_sample_id_key, update_columns: app_user_id}}, descriptor_id: $descriptor_id, rating: $rating}, on_conflict: {constraint: evaluation_descriptor_evaluation_id_descriptor_id_key, update_columns: rating}) {
    id
    rating
  }
}
`;

interface EvaluateSampleDescriptorVars {
  sample_id: string;
  app_user_id: string;
  descriptor_id: string;
  rating: number;
}

interface EvaluateSampleDescriptorData {
  insert_evaluation_descriptor_one: {
    id: string;
    rating: number;
  }
}


export default function useEvaluateSampleDescriptor(options?: MutationHookOptions<EvaluateSampleDescriptorData, EvaluateSampleDescriptorVars>) {
  return useMutation<EvaluateSampleDescriptorData, EvaluateSampleDescriptorVars>(EvaluateSampleDescriptor, options);
}