import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const INSERT_OPTION_MUTATION = gql`
  mutation InsertOption(
    $option_id: uuid!
    $sort_position: Int!
    $question_id: uuid!
    $text: String!
  ) {
    insert_SUR_survey_question_option_one(
      object: {
        id: $option_id
        question_id: $question_id
        sort_position: $sort_position
        text: $text
      }
    ) {
      id
      question_id
      sort_position
      text
    }
  }
`;

export interface InsertOptionData {
  insert_SUR_survey_question_option_one: {
    id: string;
    question_id: string;
    sort_position: number;
    text: string;
  }
}

export interface InsertOptionVariables {
  option_id: string | null;
  question_id: string;
  sort_position: number;
  text: string;
}

export function useInsertOptionMutation(
  options?:
    | MutationHookOptions<InsertOptionData, InsertOptionVariables>
    | undefined
) {
  return useMutation<InsertOptionData, InsertOptionVariables>(
    INSERT_OPTION_MUTATION,
    options
  );
}
