import React from "react";
import { css } from "@emotion/react";
import Typography from "../../../components/Typography";
import { gql, useQuery } from "@apollo/client";

const cssParent = css`
  background-color: #111111;
  display: flex;
  color: white;
  height: 65px;
`;
const cssImage = css`
  flex: auto;
  background-size: cover;
  background-position: center;
  box-shadow: inset -15px 0 20px -5px #111111;
`
const cssInfoBox = css`
  flex: initial;
  padding: 10px 10px;
`

interface OverviewProps {
  id: string;
}

interface QueryData {
  project_by_pk: {
    name: string;
    description: string;
    image?: string;
  }
  app_user: [
    {
      display_name: string;
    }
  ]
}

interface QueryVars {
  id: string;
}

const QUERY = gql`
query GetSessionOverview($id: uuid!) {
  project_by_pk(id: $id) {
    name
    description
    image
  }
  app_user(limit: 1) {
    display_name
  }
}
`;

export default function Overview(props: OverviewProps) {
  const { loading, error, data } = useQuery<QueryData, QueryVars>(QUERY,{ variables: { id: props.id } });



  if (loading) {
    return <div css={cssParent}></div>
  }
  if (error) {
    return <div css={cssParent}><Typography role="error">Error loading overview</Typography></div>
  }

  const cssImageDynamic = data?.project_by_pk.image ? css`
    background-image: url("${data.project_by_pk.image}");
  ` : null;
  const clientName = data?.app_user[0].display_name || '...';
  const projectName = data?.project_by_pk.name || '...';

  return (
    <div css={cssParent}>
      <div css={[cssImage, cssImageDynamic]}/>
      <div css={cssInfoBox}>
        <Typography role="label">Client</Typography>
        <Typography>{clientName}</Typography>
      </div>
      <div css={cssInfoBox}>
        <Typography role="label">Project</Typography>
        <Typography>{projectName}</Typography>
      </div>
    </div>
  );
};
