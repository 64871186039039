import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const getMixQuery = gql`
query GetMix($mix_id: uuid!) {
  MIX_mix_by_pk(id: $mix_id) {
    id
    name
    description
    image
    owner {
      id
      display_name
    }
    created_at
    channels
    project_id
    ratings(order_by: {created_at: desc}) {
      id
      created_at
      updated_at
      device_id
      rating
      comments
    }
  }
}
`;

export interface GetMixVariables {
  mix_id: string;
}

export interface GetMixData {
  MIX_mix_by_pk: {
    id: string;
    name: string;
    description?: string;
    image?: string;
    owner?: {
      id: string;
      display_name: string;
    }
    created_at: Date
    channels: number[]
    project_id: string;
    ratings: {
      id: string;
      created_at: string;
      updated_at: string;
      device_id: string | null;
      rating: number | null;
      comments: string | null;
    }[]
  }
}

export function useMixInfo(
  options?:
    | QueryHookOptions<
        GetMixData,
        GetMixVariables
      >
    | undefined
) {
  return useQuery<
    GetMixData,
    GetMixVariables
  >(getMixQuery, options);
}
