import React, { ReactNode } from "react";
import { css } from "@emotion/react";


interface TypographyProps {
  role?: 'heading' | 'subheading' | 'paragraph' | 'error' | 'label',
  center?: boolean,
  dark?: boolean,
  children?: ReactNode
}


const styles = {
  base: css`
    color: white;
  `,
  baseDark: css`
    color: black;
  `,
  center: css`
    text-align: center;
  `,
  heading: css`
    font-size: 34px;
    font-weight: medium;
    letter-spacing: 0.25px;
  `,
  subheading: css`
    font-size: 20px;
    font-weight: medium;
    letter-spacing: 0.15px;
  `,
  paragraph: css`
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.5px;
  `,
  label: css`
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.5px;
  `,
  error: css`
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.5px;
  `
}



export default function Typography(props: TypographyProps) {
  let activeStyles = [props.dark? styles.baseDark : styles.base];
  if (props.role) {
    activeStyles.push(styles[props.role])
  }
  if (props.center) {
    activeStyles.push(styles.center)
  }
  return <div css={activeStyles}>{props.children}</div>
}
