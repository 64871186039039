import { Box, CardContent, TextField } from "@material-ui/core";
import React, { useState } from "react";
import SurveyCard from "../../../../components/SurveyCard";
import { useDebouncedEffect } from "../../../../util/useDebouncedEffect";
import { useIsMount } from "../../../../util/useIsMount";
import SurveyQRCodePrinter from "./SurveyQRCodePrinter";

export interface InformationCardProps {
  qrCodes: {id: string, code: string}[];
  surveyId: string;
  internalName: string;
  name: string;
  description: string;
  onUpdate: (internalName: string, name: string, description: string) => void;
}

export default function InformationCard(p: InformationCardProps) {
  const isFirstRender = useIsMount();
  const [internalName, setInternalName] = useState(p.internalName);
  const [name, setName] = useState(p.name);
  const [description, setDescription] = useState(p.description);

  useDebouncedEffect(
    () => {
      if (isFirstRender) {
        return;
      }
      p.onUpdate(internalName, name, description);
    },
    100,
    [internalName, name, description]
  );

  return (
    <SurveyCard topStripe>
      <CardContent>
        <Box display="flex" flexDirection="row">
          <Box flexGrow={1} marginRight={2}>
            <TextField
              fullWidth
              label="Internal Name"
              value={internalName}
              onChange={(e) => setInternalName(e.target.value)}
            />
            <TextField
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Box marginTop={1}>
              <TextField
                fullWidth
                label="Description"
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>
          </Box>
          {/* <SurveyQRCodePrinter surveyId={p.surveyId} surveyName={p.name} qrCodes={p.qrCodes}/> */}
        </Box>
      </CardContent>
    </SurveyCard>
  );
}
