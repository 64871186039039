import React from "react";
import { css } from "@emotion/react";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface HeartRank {
  color: string;
  size: number;
}

const ranks = [
  {
    color: "rgb(29,38,0)",
    size: 55,
  },
  {
    color: "rgb(60,85,5)",
    size: 50,
  },
  {
    color: "rgb(104,132,0)",
    size: 47.5,
  },
  {
    color: "rgb(154,173,0)",
    size: 45,
  },
  {
    color: "rgb(183,215,48)",
    size: 42.5,
  },
  {
    color: "rgb(210,243,96)",
    size: 40,
  },
  {
    color: "rgb(228,248,160)",
    size: 37.5,
  },
  {
    color: "rgb(246,253,223)",
    size: 35,
  },
  {
    color: "rgb(255,255,255)",
    size: 32.5,
  },
  {
    color: "rgb(255,255,255)",
    size: 30,
  },
] as HeartRank[];

interface HeartProps {
  intensity: number;
}

export default function Heart(p: HeartProps) {
  const bounded = Math.min(Math.max(p.intensity, 0), 1);
  const rank = getRank(bounded);
  const style = css`
    color: ${rank.color};
    font-size: ${rank.size}px;
    stroke: grey;
    stroke-width: 5;
  `;
  return <FontAwesomeIcon icon={faHeart} css={style} />;
}

function getRank(intensity: number): HeartRank {
  return ranks[Math.round(intensity * 9)];
}
