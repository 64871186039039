import { css } from "@emotion/react";
import { CardContent, Typography } from "@material-ui/core";
import React from "react";
import SurveyCard from "../../components/SurveyCard";
import ErrorNotice from "./ErrorNotice";
import QMultiInput from "./inputs/QMultiInput";

export interface QuestionCardConstantProps {
  id: string;
  index: number;
  type: string;
  name: string;
  description: string;
  required: boolean;
  min_value: number | null;
  max_value: number | null;
  validationMode: string | null;
  validationCount: number | null;
  validationErrorMessage: string | null;
  options: {
    id: string;
    text: string;
  }[];
}

export interface QuestionCardProps extends QuestionCardConstantProps {
  value: string | null;
  errorMessage: string | null;
  onValueChanged: (newValue: string | null) => void;
}

export default function QuestionCard(p: QuestionCardProps) {
  return (
    <SurveyCard
      hasError={p.errorMessage != null}
      css={css`
        width: 100%;
      `}
    >
      <CardContent>
        <Typography variant="subtitle1">
          <b>Q{p.index + 1}. </b>
          {p.name}
          {p.required ? (
            <span
              css={css`
                color: #ff2222;
                font-size: 20px;
                line-height: 10px;
              `}
            >
              {" "}
              *
            </span>
          ) : null}
        </Typography>
        {p.description == null ? null : (
          <Typography variant="body2">{p.description}</Typography>
        )}
        <QMultiInput
          type={p.type}
          value={p.value}
          minValue={p.min_value == null ? undefined : p.min_value}
          maxValue={p.max_value == null ? undefined : p.max_value}
          onValueChange={p.onValueChanged}
          options={p.options.map((o) => o.text)}
        />
        {p.errorMessage && <ErrorNotice errorMessage={p.errorMessage}/>}
      </CardContent>
    </SurveyCard>
  );
}
