import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const updateMixQuery = gql`
mutation UpdateMix($id: uuid!, $name: String!, $description: String, $image: String, $channels: _int4!) {
  update_MIX_mix_by_pk(pk_columns: {id: $id}, _set: {name: $name, description: $description, image: $image, channels: $channels}) {
    id
    name
    description
    image
    created_at
    channels
    owner {
      id
      display_name
    }
  }
}
`;

export interface UpdateMixData {
  update_MIX_mix_by_pk: {
    id: string;
    name: string;
    description: string;
    image: string;
    created_at: Date;
    channels: string;
    owner?: {
      id: string;
      display_name: string;
    }
  }
}

export interface UpdateChannelMetadataVariables {
  id: string;
  name: string;
  description?: string;
  image?: string;
  channels?: string
}

export function useUpdateMix(
  options?: MutationHookOptions<UpdateMixData, UpdateChannelMetadataVariables> | undefined
) {
  return useMutation<UpdateMixData, UpdateChannelMetadataVariables>(
    updateMixQuery,
    options
  );
}
