import React from "react";
import LexiconHOC from "../../lexicon/LexiconHOC";
import { useHistory } from "react-router-dom";
import BottomTabs from "../../layout/BottomTabs";
import Layout from "../../layout/Layout";

interface LexiconPageProps {
  lexiconId: string;
}

export default function LexiconPage(p: LexiconPageProps) {
  const history = useHistory();
  const handleDescriptorSelected = (descriptorId: string) => {
    history.push(`/app/lexicon/${p.lexiconId}/${descriptorId}`);
  };

  return (
    <Layout footer={<BottomTabs />}>
      <LexiconHOC
        lexiconId={p.lexiconId}
        onDescriptorSelected={handleDescriptorSelected}
      />
    </Layout>
  );
}
