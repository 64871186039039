import React from "react";
import { css } from "@emotion/react";
import HamburgerMenu from "./HamburgerMenu";
import Logo from "./Logo";

const styles = {
  container: css`
    padding: 5px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto;
    align-items: center;
    gap: 0px 0px;
    background-color: black;
  `,
}

const Header = () => {
  return (<div css={styles.container}>
    <HamburgerMenu/>
    <div/>
    <Logo/>
  </div>);
}

export default Header