import React from "react";
import Layout from "../../layout/Layout";
import BottomTabs from "../../layout/BottomTabs";
import DescriptorDetails from "../../descriptor/DescriptorDetails";
import Navigator from "../../layout/Navigator";
import useLexiconName from "../../queries/LexiconName";
import useDescriptorName from "../../queries/DescriptorName";

interface LexiconDescriptorPageProps {
  lexiconId: string;
  descriptorId: string;
}

const LexiconDescriptorPage = (p: LexiconDescriptorPageProps) => {
  const { data: lexiconData } = useLexiconName(p.lexiconId);
  const { data: descriptorData } = useDescriptorName(p.descriptorId);
  const lexiconName = lexiconData?.lexicon_by_pk?.name || "";
  const descriptorName = descriptorData?.descriptor_by_pk?.name || "";

  return (
    <Layout
      nav={
        <Navigator
          parentLink={`/app/lexicon/${p.lexiconId}`}
          parent={lexiconName}
          current={descriptorName}
        />
      }
      footer={<BottomTabs />}
    >
      <DescriptorDetails descriptorId={p.descriptorId} />
    </Layout>
  );
};

export default LexiconDescriptorPage;
