import * as m from "@material-ui/core";
import { css } from "@emotion/react";
import { Box, Button, Card } from "@material-ui/core";
import { range } from "lodash";
import React, { Fragment, useEffect, useRef, useState } from "react";
import ApolloRouter from "../../../../util/ApolloRouter";
import { useCreateMix } from "../../../queries/CreateMix";
import { useProjectMixes } from "../../../queries/GetProjectMixes";
import { useUpdateMix } from "../../../queries/UpdateMix";
import LoadableImage from "../../components/LoadableImage";
import ImageUpload from "../../components/ImageUpload";
import { useMixInfo } from "../../../queries/GetMix";
import { useSessionChannels } from "../../../queries/GetSessionChannels";
import { channelDefaultBackgrounds } from "./ChannelsTab";
import { MixDeleteButton } from "./MixDeleteButton";

const mixDefaultBackground =
  "https://res.cloudinary.com/est-analytical/image/upload/v1626983453/mxtanglu4m1vgjidpcsb.png";

const styles = {
  container: css`
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    height: calc(100vh - 160px);
  `,
};

export interface MixesTabProps {
  sessionId: string;
}

export function MixesTab(p: MixesTabProps) {
  const [mixId, setMixId] = useState<string>();

  const [createMix] = useCreateMix();
  const handleCreateMixClick = () => {
    createMix({ variables: { project_id: p.sessionId } }).then((response) => {
      if (response.data?.insert_MIX_mix_one.id) {
        setMixId(response.data.insert_MIX_mix_one.id);
      }
    });
  };
  const handleMixDeleted = () => {
    setMixId(undefined);
  }

  return (
    <div css={styles.container}>
      <Box display="flex" flexDirection="column" height="100%" marginRight={1}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          onClick={handleCreateMixClick}
        >
          Create Mix
        </Button>
        <Box
          marginTop={1}
          padding={1}
          flex="1 1 0px"
          style={{overflowY: "auto"}}
          component={Card}
        >
          <MixSelector
            sessionId={p.sessionId}
            selectedMixId={mixId}
            onMixSelected={setMixId}
          />
        </Box>
      </Box>
      <Box component={Card} padding={2} style={{ overflowY: "auto" }}>
        {mixId ? <MixDetails mixId={mixId} onMixDeleted={handleMixDeleted} /> : <NoMixSelected />}
      </Box>
    </div>
  );
}

const channelSelectorStyles = {
  container: css`
    display: flex;
    flex-direction: column;
  `,
  channel: css`
    flex: 1 1 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 2px;
    margin-top: 2px;
    min-height: 50px;
    // background-color: white;
  `,
  channelText: css`
    text-align: center;
    font-size: 1em !important;
    text-overflow: ellipsis;
    color: white;
    text-shadow: 1px 1px 1px black;
  `,
};

function MixSelector(p: {
  sessionId: string;
  selectedMixId?: string;
  onMixSelected(mixId?: string): void;
}) {
  const query = useProjectMixes({
    variables: { id: p.sessionId },
    pollInterval: 1000,
  });

  return (
    <div css={channelSelectorStyles.container}>
      {query.data?.project_by_pk
        ? query.data.project_by_pk.mixes.map((mix) => {
            const isSelected = p.selectedMixId === mix.id;
            const bgImage = mix.image || mixDefaultBackground;
            const background = isSelected
              ? `center/cover url(${bgImage})`
              : `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), center/cover url(${bgImage})`;
            return (
              <m.Card
                css={channelSelectorStyles.channel}
                style={{
                  background: background,
                  // backgroundImage: `url(${
                  //   channel.image ||
                  //   channelDefaultBackgrounds[channel.channel_index % 2]
                  // })`,
                  zIndex: isSelected ? 999 : 0,
                }}
                elevation={isSelected ? 5 : 1}
                onClick={() => p.onMixSelected(mix.id)}
              >
                <span css={channelSelectorStyles.channelText}>{mix.name}</span>
              </m.Card>
            );
          })
        : range(20).map((index) => (
            <div key={index} css={channelSelectorStyles.channel}>
              ...
            </div>
          ))}
    </div>
  );
}

function MixDetails(p: { mixId: string, onMixDeleted: () => void; }) {
  const mixInfo = useMixInfo({
    fetchPolicy: "no-cache",
    variables: { mix_id: p.mixId },
  });

  return (
    <ApolloRouter
      loadable={mixInfo}
      loading={() => <div></div>}
      error={() => <div>error</div>}
      ready={(data) =>
        data.MIX_mix_by_pk != null ? (
          <Fragment>
            <MixDetailsHydrated key={p.mixId} onMixDeleted={p.onMixDeleted} {...data.MIX_mix_by_pk} />
          </Fragment>
        ) : (
          <div>Error loading mix with id {p.mixId}</div>
        )
      }
    />
  );
}

function MixDetailsHydrated(p: {
  id: string;
  name: string;
  description?: string;
  image?: string;
  owner?: {
    id: string;
    display_name: string;
  };
  created_at: Date;
  channels: number[];
  project_id: string;
  ratings: {
    id: string;
    created_at: string;
    updated_at: string;
    device_id: string | null;
    rating: number | null;
    comments: string | null;
  }[];
  onMixDeleted: () => void;
}) {
  const [dirty, setDirty] = useState(false);
  const [activeId, setActiveId] = useState(p.id);
  const [name, setName] = useState(p.name);
  const [description, setDescription] = useState(p.description);
  const [channels, setChannels] = useState(p.channels);
  const [image, setImage] = useState(p.image);
  const firstUpdate = useRef(true);
  const [update] = useUpdateMix();

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (p.id !== activeId) {
      console.log("new index");
      setActiveId(p.id);
      setName(p.name);
      setDescription(p.description);
      setImage(p.image);
      setChannels(p.channels);

      // Prevent this effect from being called as a result of changing the state above
      firstUpdate.current = true;
      return;
    }

    setDirty(true);
    const saveTimeout = setTimeout(() => {
      console.log("SAVING TO DATABASE");
      update({
        variables: {
          id: p.id,
          name: name,
          description: description,
          image: image,
          channels: `{${channels.join(", ")}}`,
        },
      }).then(() => {
        setDirty(false);
      });
    }, 500);
    return () => {
      clearTimeout(saveTimeout);
    };
  }, [p.id, name, description, image, channels]);

  const handleChannelChange = (index: number, value: number) => {
    const newChannels = [...channels];
    newChannels[index] = value;
    setChannels(newChannels);
  };

  const handleMixDeleted = () => {
    p.onMixDeleted();
  }

  return (
    <m.Box display="flex" flexDirection="column" overflow="scroll">
      <m.Box display="flex" justifyContent="space-between">
        <m.Typography
          variant="h4"
          align="center"
          style={{ color: dirty ? "gray" : "black" }}
        >
          {name}
        </m.Typography>
        <MixDeleteButton mixId={p.id} onMixDeleted={handleMixDeleted}/>
      </m.Box>
      <m.Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        gridTemplateRows="auto 1fr"
        gridGap="1em 1em"
      >
        <m.Box>
          <m.TextField
            value={name}
            label="Mix name"
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
          <m.TextField
            value={description}
            label="Description"
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            multiline
            rows={8}
          />
        </m.Box>
        <m.Box>
          <m.InputLabel>Image</m.InputLabel>

          <LoadableImage
            url={image}
            css={css`
              height: 150px;
            `}
          />
          <ImageUpload onSuccess={setImage} />
        </m.Box>
      </m.Box>
      <ChannelControl
        projectId={p.project_id}
        channels={channels}
        onChannelChange={handleChannelChange}
      />
      <Ratings ratings={p.ratings} />
    </m.Box>
  );
}

const channelControlStyles = {
  channel: css`
    flex: 1 1 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    margin-bottom: 2px;
    margin-top: 2px;
    min-height: 100px;
  `,
  channelText: css`
    text-align: center;
    font-size: 1.5em !important;
    text-overflow: ellipsis;
    color: white;
    text-shadow: 2px 2px 2px black;
  `,
};

function ChannelControl(p: {
  projectId: string;
  channels: number[];
  onChannelChange(index: number, value: number): void;
}) {
  const query = useSessionChannels({ variables: { id: p.projectId } });

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr 1fr 1fr"
      gridTemplateRows="auto auto auto"
      gridGap="5px 5px"
    >
      <Box gridColumn="1 / 5">
        <m.Typography variant="h6">Flow Values</m.Typography>
      </Box>
      {p.channels.map((value, index) => {
        const maybeChannel = query.data?.project_by_pk?.channels[index];
        const bgImage =
          maybeChannel?.image || channelDefaultBackgrounds[index % 2];
        const background = `linear-gradient( rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.8) 70%), center/cover url(${bgImage})`;
        const name = maybeChannel?.name || "...";
        return (
          <m.Card
            css={channelControlStyles.channel}
            style={{
              background: background,
            }}
          >
            <span css={channelControlStyles.channelText}>{name}</span>
            {maybeChannel != null ? (
              <m.Slider
                step={1}
                min={0}
                max={5}
                valueLabelDisplay="on"
                value={value/20}
                onChange={(e, newVal) =>
                  p.onChannelChange(index, 20 * (newVal as number))
                }
              />
            ) : null}
          </m.Card>
        );
      })}
    </Box>
  );
}

function Ratings(p: {
  ratings: {
    id: string;
    created_at: string;
    updated_at: string;
    device_id: string | null;
    rating: number | null;
    comments: string | null;
  }[];
}) {
  if (p.ratings.length === 0) {
    return (
      <Box marginTop={2}>
        <m.Typography variant="h6">Ratings</m.Typography>
        <m.Typography>Nobody has rated this mix yet.</m.Typography>
      </Box>
    );
  }
  return (
    <Box marginTop={2}>
      <m.Typography variant="h6">Ratings</m.Typography>
      <m.TableContainer component={m.Card}>
        <m.Table>
          <m.TableHead>
            <m.TableRow>
              <m.TableCell>Submitted</m.TableCell>
              <m.TableCell>Rating</m.TableCell>
              <m.TableCell>Comments</m.TableCell>
            </m.TableRow>
          </m.TableHead>
          <m.TableBody>
            {p.ratings.map((rating) => (
              <m.TableRow>
                <m.TableCell>{`${new Date(
                  rating.created_at
                ).toLocaleDateString()} ${new Date(
                  rating.created_at
                ).toLocaleTimeString()}`}</m.TableCell>
                <m.TableCell>
                  {rating.rating == null ? (
                    <i>No rating provided</i>
                  ) : (
                    `${rating.rating}/5`
                  )}
                </m.TableCell>
                <m.TableCell>
                  {rating.comments == null ? (
                    <i>No comments provided</i>
                  ) : (
                    rating.comments
                  )}
                </m.TableCell>
              </m.TableRow>
            ))}
          </m.TableBody>
        </m.Table>
      </m.TableContainer>
    </Box>
  );
}

function NoMixSelected() {
  return (
    <Box display="flex">
      <m.Typography>
        Create or select a mix using the panel on the left.
      </m.Typography>
    </Box>
  );
}
