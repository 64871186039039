import { css } from "@emotion/react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TableBody,
  Box,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import ApolloRouter from "../../../util/ApolloRouter";
import {
  ListUnlinkedSurveysData,
  useListUnlinkedSurveysQuery,
} from "./queries/ListSurveys";

export interface SurveyListProps {}

function SurveyListReady(p: ListUnlinkedSurveysData) {
  const history = useHistory();

  return (
    <Box>
      <Typography variant="h4">Unlinked Surveys</Typography>
      <Typography variant="subtitle1">This screen allows you to create and manage surveys which are not associated with any particular session.</Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Survey</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Responses</TableCell>
              <TableCell
                css={css`
                  min-width: 100px;
                `}
              >
                <Button
                  css={css`
                    width: 100px;
                  `}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => history.push(`/admin/surveys/new`)}
                >
                  Create
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {p.SUR_survey.map((survey) => (
              <TableRow
                key={survey.id}
                onDoubleClick={() =>
                  history.push(`/admin/surveys/${survey.id}`)
                }
              >
                <TableCell>{survey.internal_name}</TableCell>
                <TableCell>{survey.name}</TableCell>
                <TableCell>
                  <Link to={`/admin/surveys/${survey.id}/responses`}>
                    {survey.responses_aggregate.aggregate.count}
                  </Link>
                </TableCell>
                <TableCell>
                  <Button
                    css={css`
                      width: 100px;
                    `}
                    variant="contained"
                    size="small"
                    onClick={() => history.push(`/admin/surveys/${survey.id}`)}
                  >
                    Manage
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default function SurveyList(p: SurveyListProps) {
  const query = useListUnlinkedSurveysQuery({ pollInterval: 5000 });
  console.log("survey list render");
  return (
    <ApolloRouter
      loadable={query}
      ready={(data) => <SurveyListReady {...data} />}
    />
  );
}
