import React from "react";
// import { Redirect } from "react-router-dom";
// import BottomTabs from "../../layout/BottomTabs";
// import Layout from "../../layout/Layout";
// import useProjectLexiconName from "../../queries/ProjectLexiconName";
// import ProjectOverview from "../../project/ProjectInfo";
// import {
//   QRCodeResult,
//   QRCodeType,
// } from "../../components/Scanner/useQRCode";

interface ProjectScanPageProps {
  projectId: string;
}

export default function ProjectScanPage(p: ProjectScanPageProps) {
  return <div/>
  // const { data: lexiconInfo } = useProjectLexiconName(p.projectId);
  // const lexiconId = lexiconInfo?.project_by_pk?.lexicon?.id;

  // const [, setRedirecting] = useState(false);
  // const [redirecttTo, setRedirectTo] = useState("");

  // const redirect = (to: string) => {
  //   setRedirecting(true);
  //   setTimeout(() => {
  //     setRedirectTo(to);
  //   }, 500);
  // };

  // const handleScan = (result: QRCodeResult) => {
  //   const { type: objectType, objectId, projectId } = result;
  //   if (objectType === QRCodeType.project) {
  //     redirect(`/app/project/${objectId}/samples`);
  //   }
  //   if (objectType === QRCodeType.sample) {
  //     redirect(`/app/project/${projectId}/samples/${objectId}`);
  //   }
  //   if (objectType === QRCodeType.lexicon) {
  //     if (objectId === lexiconId) {
  //       redirect(`/app/project/${p.projectId}/lexicon`);
  //     } else {
  //       redirect(`/app/lexicon/${objectId}`);
  //     }
  //   }
  //   if (objectType === QRCodeType.descriptor) {
  //     redirect(`/app/descriptor/${objectId}`);
  //   }
  // };

  // return (
  //   <Layout
  //     overview={<ProjectOverview projectId={p.projectId} />}
  //     footer={<BottomTabs projectId={p.projectId} />}
  //   >
  //     {redirecttTo ? <Redirect to={redirecttTo} /> : null}
  //     {/* {lexiconId && !redirecting ? <Scanner onScan={handleScan} /> : null} */}
  //   </Layout>
  // );
}
