import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const DELETE_OPTION_MUTATION = gql`
  mutation DeleteOption($option_id: uuid!) {
    delete_SUR_survey_question_option_by_pk(id: $option_id) {
      id
      question_id
    }
  }
`;

export interface DeleteOptionData {
  delete_SUR_survey_question_option_by_pk: {
    id: string;
    question_id: string;
  }
}

export interface DeleteOptionVariables {
  option_id: string;
}

export function useDeleteOptionMutation(
  options?:
    | MutationHookOptions<DeleteOptionData, DeleteOptionVariables>
    | undefined
) {
  return useMutation<DeleteOptionData, DeleteOptionVariables>(
    DELETE_OPTION_MUTATION,
    options
  );
}
