import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

const query = gql`
query GetProjectSurveyResultsCsv($project_id: uuid!) {
  project_by_pk(id: $project_id) {
    survey {
      response_csv {
        csv
      }
    }
  }
}

`;

export interface GetProjectSurveyResultsCsvVariables {
  project_id: string;
}

export interface GetProjectSurveyResultsCsvData {
  project_by_pk: {
    survey: {
      response_csv: {
        csv: string;
      }
    }
  }
}

export function useProjectSurveyResultsCsv(
  options?:
    | QueryHookOptions<
        GetProjectSurveyResultsCsvData,
        GetProjectSurveyResultsCsvVariables
      >
    | undefined
) {
  return useQuery<
    GetProjectSurveyResultsCsvData,
    GetProjectSurveyResultsCsvVariables
  >(query, options);
}
