import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { ProjectSummaryTableProps } from "./ProjectSummaryTable";

export const projectSummaryTableQuery = gql`
  query ProjectReport($project_id: uuid!) {
    project_by_pk(id: $project_id) {
      id
      name
      share_with_evaluators
      samples {
        id
        name
        evaluation_summary {
          count
          liking_mean
          liking_median
          liking_rank
          liking_entries
          concept_fit_mean
          concept_fit_median
          concept_fit_rank
          concept_fit_entries
          just_about_right_percent
          just_about_right_rank
          score_2_sum
          tasters_sum
        }
        evaluation_descriptor_summary {
          descriptor {
            id
            name
          }
          tasters_count
          score_1_percent_of_tasters
          score_3_percent_of_tasters
        }
        further_increase: evaluation_descriptor_summary(
          order_by: {
            score_1_percent_of_tasters: desc
            descriptor: { name: asc }
          }
          where: { score_1_percent_of_present: { _gte: "25" } }
        ) {
          descriptor {
            name
          }
        }
        further_reduce: evaluation_descriptor_summary(
          order_by: {
            score_3_percent_of_tasters: desc
            descriptor: { name: asc }
          }
          where: { score_3_percent_of_present: { _gte: "25" } }
        ) {
          descriptor {
            name
          }
        }
        evaluation_word_cloud_summary {
          word
          count
        }
      }
    }
    evaluation_summary_view_aggregate(
      where: { sample: { project_id: { _eq: $project_id } } }
    ) {
      aggregate {
        max {
          liking_rank
          just_about_right_rank
          concept_fit_rank
        }
      }
    }
    evaluation_aggregate(where: {sample: {project_id: {_eq: $project_id}}}) {
      aggregate {
        count(columns: app_user_id, distinct: true)
      }
    }
  }
`;

export function getSummaryTableData(
  data: ProjectSummaryTableData
): ProjectSummaryTableProps {
  return {
    projectId: data.project_by_pk?.id,
    evaluators: data.evaluation_aggregate.aggregate.count,
    samples:
      data.project_by_pk?.samples.map((sample) => ({
        id: sample.id,
        name: sample.name,
        ranks: {
          conceptFit: sample.evaluation_summary?.concept_fit_rank,
          liking: sample.evaluation_summary?.liking_rank,
          justAboutRight: sample.evaluation_summary?.just_about_right_rank,
        },
        furtherIncreaseDescriptors: sample.further_increase.map(
          (inc) => inc.descriptor.name
        ),
        furtherReduceDescriptors: sample.further_reduce.map(
          (dec) => dec.descriptor.name
        ),
        words: sample.evaluation_word_cloud_summary.map((word) => ({
          text: word.word,
          value: word.count,
        })),
      })) || [],
    maximumRanks: {
      conceptFit:
        data.evaluation_summary_view_aggregate?.aggregate.max.concept_fit_rank,
      justAboutRight:
        data.evaluation_summary_view_aggregate?.aggregate.max
          .just_about_right_rank,
      liking: data.evaluation_summary_view_aggregate?.aggregate.max.liking_rank,
    },
  };
}

export interface ProjectSummaryTableVariables {
  project_id: string;
}

export interface ProjectSummaryTableData {
  project_by_pk?: {
    id: string;
    name: string;
    share_with_evaluators: boolean;
    samples: {
      id: string;
      name: string;
      evaluation_summary: {
        count?: number;
        liking_mean?: number;
        liking_median?: number;
        liking_rank?: number;
        liking_entries: number;
        concept_fit_mean?: number;
        concept_fit_median?: number;
        concept_fit_rank?: number;
        concept_fit_entries: number;
        just_about_right_percent?: number;
        just_about_right_rank?: number;
        score_2_sum?: number;
        tasters_sum?: number;
      };
      evaluation_word_cloud_summary: {
        word: string;
        count: number;
      }[];
      evaluation_descriptor_summary: {
        descriptor: {
          id: string;
          name: string;
        }
        tasters_count: number;
        score_1_percent_of_tasters: number;
        score_3_percent_of_tasters: number;
      }[];
      further_increase: {
        descriptor: {
          name: string;
        };
      }[];
      further_reduce: {
        descriptor: {
          name: string;
        };
      }[];
    }[];
  };
  evaluation_summary_view_aggregate?: {
    aggregate: {
      max: {
        liking_rank?: number;
        just_about_right_rank?: number;
        concept_fit_rank?: number;
      };
    };
  };
  evaluation_aggregate: {
    aggregate: {
      count: number;
    }
  }
}

export function useProjectSummaryTable(
  options?:
    | QueryHookOptions<ProjectSummaryTableData, ProjectSummaryTableVariables>
    | undefined
) {
  return useQuery<ProjectSummaryTableData, ProjectSummaryTableVariables>(
    projectSummaryTableQuery,
    options
  );
}

export const updateProjectSharingMutation = gql`
  mutation UpdateProjectSharing(
    $project_id: uuid!
    $share_with_evaluators: Boolean!
  ) {
    update_project_by_pk(
      pk_columns: { id: $project_id }
      _set: { share_with_evaluators: $share_with_evaluators }
    ) {
      id
      share_with_evaluators
    }
  }
`;

export interface UpdateProjectSharingVariables {
  project_id: string;
  share_with_evaluators: boolean;
}

export interface UpdateProjectSharingData {
  project_by_pk?: {
    id: string;
    share_with_evaluators: boolean;
  };
}
