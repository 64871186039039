import { gql, useLazyQuery } from '@apollo/client';
import { useState } from 'react'
import { useSession } from '../../SessionContext';

interface LoginQueryData {
  authenticate: {
    jwt?: string;
    success: boolean
  }
}

interface LoginQueryVars {
  secret: string;
}

const LOGIN_QUERY = gql`
query Login($secret: String!) {
  authenticate(secret: $secret) {
    jwt
    success
  }
}
`;

export default function useLogin() {
  const { setJwt } = useSession();
  const [ error, setError ] = useState('');
  const [ success, setSuccess ] = useState(false);
  const onQueryComplete = (data: LoginQueryData) => {
    if (data.authenticate.success) {
      setSuccess(true);
      setJwt(data.authenticate.jwt!)
    } else {
      setSuccess(false)
      setError('Invalid login');
    }
  }
  const [runLoginQuery, { loading }] = useLazyQuery<
    LoginQueryData,
    LoginQueryVars
  >(LOGIN_QUERY, {onCompleted: onQueryComplete, onError: (e) => {setSuccess(false); setError(e.message)}, fetchPolicy: "no-cache"});

  const login = (secret: string) => {
      setSuccess(false);
      return runLoginQuery({variables: {secret}})
  }
  const logout = () => {
    setJwt('');
  }

  return {
      login,
      logout,
      state: success ? 'success' : loading ? 'loading' : error ? 'error' : 'idle',
      error
  }

}