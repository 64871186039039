import React from "react";
import Tabs from '../../Session/layout/tabs'

interface BottomTabsProps {
  projectId?: string
}

const BottomTabs = (p: BottomTabsProps) => {
  return <Tabs projectId={p.projectId}/>;
}

export default BottomTabs