import { css } from "@emotion/react";
import { Box, Slider } from "@material-ui/core";
import React from "react";
import QInputProps from "./QInputProps";

const styles = {
  numberStyle: css`
    font-size: 20px;
    line-height: 30px;
    padding-left: 10px;
    padding-right: 10px;
  `,
};

export default function QRatingSlider(p: QInputProps) {
  const numericValue = p.value == null ? 0 : parseInt(p.value) || 0;
  const min = p.minValue == null ? 1 : p.minValue;
  const max = p.maxValue == null ? 5 : p.maxValue;

  return (
    <Box display="flex" flexDirection="row" justifyContent="center" padding={1}>
      <div css={styles.numberStyle}>{min}</div>
      <Slider
        value={numericValue}
        onChange={(e, v) => p.onValueChange(`${v}`)}
        step={1}
        marks
        min={min}
        max={max}
      />
      <div css={styles.numberStyle}>{max}</div>
    </Box>
  );
}
