import { css } from "@emotion/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import React from "react";
import StarRatingComponent from "react-star-rating-component";
import QInputProps from "./QInputProps";

const styles = {
  numberStyle: css`
    font-size: 20px;
    line-height: 40px;
    padding-left: 10px;
    padding-right: 10px;
  `,
  iconStyle: css`
    // padding-left: 2px;
    // padding-right: 2px;
  `
}

export interface QRatingGenericProps extends QInputProps {
  icon: IconProp;
  color: string;
}

export default function QRatingGeneric(p: QRatingGenericProps) {
  const numericValue = p.value == null ? 0 : parseInt(p.value) || 0;
  const min = p.minValue == null ? 1 : p.minValue;
  const max = p.maxValue == null ? 5 : p.maxValue;
  const stars = max - min + 1;

  return (
    <Box display="flex" flexDirection="row" justifyContent="center" padding={1}>
      <div css={styles.numberStyle}>{min}</div>
      <StarRatingComponent
        starColor={p.color}
        starCount={stars}
        name="rating"
        value={numericValue - min + 1}
        onStarClick={(next) => p.onValueChange(`${next + min - 1}`)}
        renderStarIcon={(next, prev, name) => (
          <FontAwesomeIcon icon={p.icon} size="2x" css={styles.iconStyle} fixedWidth/>
        )}
      />
      <div css={styles.numberStyle}>{max}</div>
    </Box>
  );
}
