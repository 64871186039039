import React from "react";
import LexiconHOC from "../../lexicon/LexiconHOC";
import { useHistory } from "react-router-dom";
import BottomTabs from "../../layout/BottomTabs";
import Layout from "../../layout/Layout";
import useProjectLexiconName from "../../queries/ProjectLexiconName";
import ProjectOverview from "../../project/ProjectInfo";

interface ProjectLexiconPageProps {
  projectId: string;
}

export default function ProjectLexiconPage(p: ProjectLexiconPageProps) {
  const history = useHistory();
  const handleDescriptorSelected = (descriptorId: string) => {
    history.push(`/app/project/${p.projectId}/lexicon/${descriptorId}`);
  };
  const { data: lexiconInfo } = useProjectLexiconName(p.projectId);
  const lexiconId = lexiconInfo?.project_by_pk?.lexicon?.id;

  return (
    <Layout overview={<ProjectOverview projectId={p.projectId}/>} footer={<BottomTabs projectId={p.projectId} />}>
      {lexiconId ? (
        <LexiconHOC
          lexiconId={lexiconId}
          onDescriptorSelected={handleDescriptorSelected}
        />
      ) : null}
    </Layout>
  );
}
