import React from "react";
import { css } from "@emotion/react";
import {
  Button,
  Container,
  Paper,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSession } from "../SessionContext";
import Routes from "./routes";
import SideBar from "./layout/SideBar";


const cssStyles = {
  parent: css`
    display: grid;
    grid-template-columns: 240px 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "sideBar content";
    background-color: #eeeeee;
  `,
  topBar: css`
    grid-area: topBar;
    background-color: rgb(67, 80, 175) !important;
    border-radius: 0px !important;
  `,
  sideBar: css`
    grid-area: sideBar;
  `,
  content: css`
    grid-area: content;
    // height: 100%;
    overflow: auto;
  `,
  spacer: css`
    flex-grow: 1;
  `,
  openAppLink: css`
    color: inherit;
    text-decoration: inherit;
  `,
};



const AdminPage = () => {
  const { isAdmin } = useSession();
  const history = useHistory();

  if (!isAdmin) {
    return (
      <div
        css={css`
          background-color: white;
        `}
      >
        <Typography variant="h3">Access denied</Typography>
        <Typography variant="subtitle1">
          Please log in as an administrator to access this page.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push("/logout");
          }}
        >
          Log out
        </Button>
      </div>
    );
  }

  return (
    <div css={cssStyles.parent}>
      <Paper css={cssStyles.sideBar} elevation={5}>
        <SideBar />
      </Paper>
      <main css={cssStyles.content}>
        <Container
          maxWidth="lg"
          css={css`padding: 20px`}
        >
          <Routes />
        </Container>
      </main>
    </div>
  );
};

export default AdminPage;
