import { gql, QueryHookOptions, useQuery } from "@apollo/client";

const ProjectLexiconName = gql`
  query ProjectLexiconName($project_id: uuid!) {
    project_by_pk(id: $project_id) {
      lexicon {
        id
        name
      }
    }
  }
`;

interface ProjectLexiconNameVars {
  project_id: string;
}

interface ProjectLexiconNameData {
  project_by_pk: {
    lexicon: {
      id: string;
      name: string;
    };
  };
}

export default function useProjectLexiconName(
  projectId: string,
  options?: QueryHookOptions<ProjectLexiconNameData, ProjectLexiconNameVars>
) {
  return useQuery<ProjectLexiconNameData, ProjectLexiconNameVars>(
    ProjectLexiconName,
    {
      variables: { project_id: projectId, ...options?.variables },
      ...options,
    }
  );
}
