import React from "react";
import { css } from "@emotion/react";
import Header from "./Header";

const styles = {
  container: css`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto 1fr auto;
    gap: 0px 0px;
    grid-template-areas:
      "header"
      "overview"
      "nav"
      "workspace"
      "footer";
  `,
  header: css`
    grid-area: header;
  `,
  overview: css`
    grid-area: overview;
  `,
  nav: css`
    grid-area: nav;
  `,
  workspace: css`
    grid-area: workspace;
    overflow-y: auto;
    background-color: black;
  `,
  footer: css`
    grid-area: footer;
  `,
}

interface LayoutProps {
  overview?: JSX.Element
  nav?: JSX.Element
  footer?: JSX.Element
}

const Layout = (p: React.PropsWithChildren<LayoutProps>) => {
  return (<div css={styles.container}>
    <Header css={styles.header}/>
    {p.overview ? <div css={styles.overview}>{p.overview}</div> : null}
    {p.nav ? <div css={styles.nav}>{p.nav}</div> : null}
    <div css={styles.workspace}>
      {p.children}
    </div>
    {p.footer ? <div css={styles.footer}>{p.footer}</div> : null}
  </div>);
}

export default Layout