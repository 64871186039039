import { gql, QueryHookOptions, useQuery } from "@apollo/client";

const SampleDetails = gql`
  query SampleDetails($sample_id: uuid!, $app_user_id: uuid!) {
    sample_by_pk(id: $sample_id) {
      id
      name
      description
      evaluations(where: {app_user_id: {_eq: $app_user_id}}) {
        id
        liking_score
        concept_fit_score
        comments
        descriptor_evaluations {
          descriptor_id
          rating
        }
      }
      descriptors(order_by: {name: asc}) {
        id
        name
      }
    }
  }
`;

interface SampleDetailsVars {
  sample_id: string;
  app_user_id: string;
}

interface SampleDetailsData {
  sample_by_pk: {
    id: string;
    name: string;
    description: string;
    evaluations: {
      id: string;
      liking_score?: number;
      concept_fit_score?: number;
      comments?: string;
      descriptor_evaluations: {
        descriptor_id: string;
        rating: number;
      }[];
    }[];
    descriptors: {
      id: string;
      name: string;
    }[];
  };
}

export default function useSampleDetails(
  sampleId: string,
  appUserId: string,
  options?: QueryHookOptions<SampleDetailsData, SampleDetailsVars>
) {
  return useQuery<SampleDetailsData, SampleDetailsVars>(SampleDetails, {
    variables: { sample_id: sampleId, app_user_id: appUserId, ...options?.variables },
    ...options,
  });
}