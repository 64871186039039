import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const updateChannelMetadataQuery = gql`
mutation UpdateChannelMetadata($id: uuid!, $name: String!, $development_code: String, $group_code: String, $solvent: String, $concentration: String, $notes: String) {
  update_project_channel_by_pk(pk_columns: {id: $id}, _set: {name: $name, development_code: $development_code, group_code: $group_code, solvent: $solvent, concentration: $concentration, notes: $notes}) {
    id
    name
    development_code
    group_code
    concentration
    solvent
    notes
  }
}
`;

export interface UpdateChannelMetadataData {
  update_project_channel_by_pk: {
    id: string;
    name: string;
    development_code: string | null;
    group_code: string | null;
    concentration: string | null;
    solvent: string | null;
    notes: string | null;
  };
}

export interface UpdateChannelMetadataVariables {
  id: string;
  name: string;
  development_code: string | null;
  group_code: string | null;
  concentration: string | null;
  solvent: string | null;
  notes: string | null;
}

export function useUpdateChannelMetadata(
  options?: MutationHookOptions<UpdateChannelMetadataData, UpdateChannelMetadataVariables> | undefined
) {
  return useMutation<UpdateChannelMetadataData, UpdateChannelMetadataVariables>(
    updateChannelMetadataQuery,
    options
  );
}
