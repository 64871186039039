import React, { useState } from "react";
import { css } from "@emotion/react";
import {
  Box,
  Button,
  Input,
  Slider,
  Snackbar,
  Typography,
} from "@material-ui/core";
import StarRating from "react-star-rating-component";
import { Alert } from "@material-ui/lab";

const styles = {
  container: css`
    background-color: rgb(234, 234, 234);
    padding: 10px;
  `,
  stars: css`
    font-size: 30px;
    text-align: center;
  `,
};

interface SampleRatingsProps {
  likingScore?: number;
  conceptFitScore?: number;
  comments?: string;
  onFeedbackSaved: (
    likingScore?: number,
    conceptFitScore?: number,
    comments?: string
  ) => Promise<void>;
}

export default function SampleRatings(p: SampleRatingsProps) {
  const [likingScore, setLikingScore] = useState(p.likingScore);
  const [conceptFitScore, setConceptFitScore] = useState(p.conceptFitScore);
  const [comments, setComments] = useState(p.comments);
  const [saving, setSaving] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setSaving(true);
    p.onFeedbackSaved(likingScore, conceptFitScore, comments)
      .then(() => {
        setSaving(false);
        setOpen(true);
      })
      .catch(() => {
        setSaving(false);
      });
  };

  return (
    <div css={styles.container}>
      <Typography align="center" variant="h4">
        Rate Sample
      </Typography>
      <Box marginTop="0px" marginBottom="10px">
        <Typography align="center" variant="h6">
          Liking
        </Typography>
        <Typography align="center" variant="subtitle2">
          How much do you like this sample?
        </Typography>
        {/* <div css={styles.stars}>
          <StarRating
            name="liking"
            value={likingScore || 0}
            starCount={5}
            onStarClick={setLikingScore}
          />
        </div> */}
        <Box margin="10px">
          <Slider
            value={likingScore}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={10}
            onChange={(e, value) => setLikingScore(value as number)}
            css={css`color: rgb(133,155,9) !important;`}
          />
        </Box>
      </Box>
      <Box marginTop="10px" marginBottom="10px">
        <Typography align="center" variant="h6">
          Concept Fit
        </Typography>
        <Typography align="center" variant="subtitle2">
          How well does this sample fit the overall concept?
        </Typography>
        <div css={styles.stars}>
          <StarRating
            name="liking"
            value={conceptFitScore || 0}
            starCount={5}
            onStarClick={setConceptFitScore}
          />
        </div>
      </Box>
      <Box marginTop="10px" marginBottom="10px">
        <Typography align="center" variant="h6">
          Comments
        </Typography>
        <Typography align="center" variant="subtitle2">
          Any additional feedback you'd like to share
        </Typography>
        <Box
          marginLeft="10px"
          marginRight="10px"
          css={css`
            background-color: white;
            font-size: 24px;
          `}
        >
          <Input
            disableUnderline
            multiline
            rows={6}
            fullWidth
            css={css`
              padding: 10px;
            `}
            value={comments}
            onChange={(e) => {
              setComments(e.target.value);
            }}
          />
        </Box>
      </Box>
      <Box margin="10px">
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={saving}
        >
          Save Feedback
        </Button>
      </Box>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Feedback saved
        </Alert>
      </Snackbar>
    </div>
  );
}
