import React from "react";
import { css } from "@emotion/react";
import logoImage from "./g.png";

interface LogoProps {
  height?: number;
}

export default function Logo(props: LogoProps) {
  const height = props.height || 40;

  const cssParent = css`
    display: flex;
    font: "Fedra Serif"
    vertical-align: middle;
    height: ${height}px;
    user-select: none;
    padding: 2px;
  `;
  const cssImage = css`
    max-width: 100%;
    max-height: 100%;
    margin-right: 5px;
  `;

  const cssAI = css`
    color: rgb(71,165,212);
    font-size: ${height*1}px;
    line-height: ${height*1}px;
    font-weight: 600;
  `;
  const cssRomi = css`
    color: rgb(41,80,128);
    font-size: ${height*1}px;
    line-height: ${height*1}px;
    font-weight: 400;
  `;

  return (
    <div css={cssParent}>
      <img css={cssImage} src={logoImage} alt="Givaudan Logo" />
      <span css={cssAI}>Aroma</span>
      <span css={cssRomi}>Sniff</span>
    </div>
  );
};
