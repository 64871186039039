import React from "react";
import Layout from "../../layout/Layout";
import BottomTabs from "../../layout/BottomTabs";
import DescriptorDetails from "../../descriptor/DescriptorDetails";
import Navigator from "../../layout/Navigator";
import useDescriptorName from "../../queries/DescriptorName";
import useProjectLexiconName from "../../queries/ProjectLexiconName";
import ProjectOverview from "../../project/ProjectInfo";

interface ProjectLexiconDescriptorPageProps {
  projectId: string;
  descriptorId: string;
}

const ProjectLexiconDescriptorPage = (p: ProjectLexiconDescriptorPageProps) => {
  const { data: lexiconData } = useProjectLexiconName(p.projectId);
  const { data: descriptorData } = useDescriptorName(p.descriptorId);
  const lexiconName = lexiconData?.project_by_pk?.lexicon?.name || "";
  const descriptorName = descriptorData?.descriptor_by_pk?.name || "";

  return (
    <Layout
      overview={<ProjectOverview projectId={p.projectId}/>}
      nav={
        <Navigator
          parentLink={`/app/project/${p.projectId}/lexicon`}
          parent={lexiconName}
          current={descriptorName}
        />
      }
      footer={<BottomTabs projectId={p.projectId}/>}
    >
      <DescriptorDetails descriptorId={p.descriptorId} />
    </Layout>
  );
};

export default ProjectLexiconDescriptorPage;
