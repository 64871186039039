import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const SWAP_QUESTIONS_MUTATION = gql`
mutation SwapQuestions($questionAId: uuid!, $questionBId: uuid!) {
  swap_SUR_survey_question(questionAId: $questionAId, questionBId: $questionBId) {
    success
  }
}
`;

export interface SwapQuestionsData {
  swap_SUR_survey_question: {
    success: boolean;
  }
}

export interface SwapQuestionsVariables {
  questionAId: string;
  questionBId: string;
}

export function useSwapQuestionsMutation(
  options?:
    | MutationHookOptions<SwapQuestionsData, SwapQuestionsVariables>
    | undefined
) {
  return useMutation<SwapQuestionsData, SwapQuestionsVariables>(
    SWAP_QUESTIONS_MUTATION,
    options
  );
}
