import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const getSessionChannelsQuery = gql`
query GetSessionChannels($id: uuid!) {
  project_by_pk(id: $id) {
    id
    channels(order_by: {channel_index: asc}) {
      id
      channel_index
      name
      description
      enabled
      flow_value
      image
    }
  }
}
`;

export interface GetSessionChannelsVariables {
  id: string;
}

export interface GetSessionChannelsData {
  project_by_pk?: {
    id: string;
    channels: {
      id: string;
      channel_index: number;
      name: string;
      description: string;
      enabled: boolean;
      flow_value: number;
      image?: string;
    }[]
  };
}

export function useSessionChannels(
  options?:
    | QueryHookOptions<
        GetSessionChannelsData,
        GetSessionChannelsVariables
      >
    | undefined
) {
  return useQuery<
    GetSessionChannelsData,
    GetSessionChannelsVariables
  >(getSessionChannelsQuery, options);
}
