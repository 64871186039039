import { Box } from "@material-ui/core";
import React from "react";
import { mapApollo } from "../../../../util/ApolloLoadable";
import ApolloRouter from "../../../../util/ApolloRouter";
import DownloadCsv from "../components/DownloadCsv";
import ResultsTable, { ResultsTableProps } from "../components/ResultsTable";
import {
  SurveyResultsData,
  useSurveyResultsQuery,
} from "../queries/SurveyResults";

export interface SurveyResultsProps {
  surveyId: string;
}

export default function SurveyResults(p: SurveyResultsProps) {
  const surveyResults = useSurveyResultsQuery({
    variables: { survey_id: p.surveyId },
    fetchPolicy: "no-cache",
  });
  return (
    <Box>
      <Box
        paddingY={1}
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
      >
        <DownloadCsv
          surveyId={p.surveyId}
          fileName={`survey_results_${p.surveyId.substring(0, 6)}.csv`}
        />
      </Box>
      <ApolloRouter
        loadable={mapApollo(surveyResults, mapQueryToTableData)}
        ready={(data) => <ResultsTable {...data} />}
      />
    </Box>
  );
}

function mapQueryToTableData(data: SurveyResultsData): ResultsTableProps {
  return {
    surveyId: data.SUR_survey_by_pk.id,
    questions: data.SUR_survey_by_pk.questions.map((q) => ({
      questionId: q.id,
      text: q.name,
      isMultiResponse: q.type.startsWith("select")
    })),
    responses: data.SUR_survey_by_pk.responses.map((r) => ({
      responseId: r.id,
      date: new Date(r.created_at),
      answers: r.question_responses.map((q) => ({
        answerId: q.id,
        questionId: q.question_id,
        value: q.value,
      })),
    })),
  };
}
