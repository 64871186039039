import React from "react";
import { css } from "@emotion/react";
import { Typography } from "@material-ui/core";

const style = {
  container: css`
    padding: 10px;
    margin: 10px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
    gap: 0px 0px;
    grid-template-areas: ". .";
    border-top: 2px solid white;
  `,
  image: css`
    height: 100px;
    width: 100px;
    background-size: cover;
    background-position: center;
    box-shadow: inset -15px 0 20px -5px #111111;
    border-radius: 10px;
  `,
};

interface DescriptorProps extends React.HTMLAttributes<HTMLDivElement> {
  descriptor: {
    id: string;
    name: string;
    description: string;
    image: string;
  };
}

const Descriptor = (p: DescriptorProps) => {
  const imageBackgroundStyle = css`
    background-image: url("${p.descriptor.image}");
  `;
  return (
    <div css={style.container} {...p}>
      <div css={[style.image, imageBackgroundStyle]} />
      <div css={css`padding: 0px 10px;`}>
        <Typography variant="h6">{p.descriptor.name}</Typography>
        <Typography variant="body2">{p.descriptor.description}</Typography>
      </div>
    </div>
  );
};

export default Descriptor;
