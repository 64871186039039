import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { useDeleteMixById } from "../../../queries/DeleteMixById";

export interface MixDeleteButtonProps {
  mixId: string;
  onMixDeleted?: () => void
}

export function MixDeleteButton(p: MixDeleteButtonProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deletionInProgress, setDeletionInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [deleteMixById] = useDeleteMixById({ variables: { mix_id: p.mixId } });

  const handleOpen = () => {
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };
  const handleConfirmDelete = () => {
    setDeletionInProgress(true);
    deleteMixById().then(() => {
      setDeletionInProgress(false);
      setDialogOpen(false);
      if (p.onMixDeleted) {
        p.onMixDeleted()
      }
    }).catch((e) => {
      setDeletionInProgress(false);
      setErrorMessage(`Failed to delete mix: ${e}`)
    })
  };

  return (
    <Fragment>
      <Button variant="contained" onClick={handleOpen}>Delete Mix</Button>
      <Dialog onClose={handleClose} open={dialogOpen}>
        <DialogTitle>Are you sure you want to delete this mix?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This mix and any associated feedback will be deleted.
            {errorMessage ? <Box style={{color: "red"}}>{errorMessage}</Box> : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={deletionInProgress}>
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} disabled={deletionInProgress}>
            Delete Mix
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
