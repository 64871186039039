import React from "react";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSession } from "../../../SessionContext";

const HamburgerMenu = () => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const match = useRouteMatch<{projectId: string;}>('/app/project/:projectId');
  const { sessionId: savedSessionId } = useSession();
  const sessionId = match?.params.projectId || savedSessionId;

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (    <div>
    <IconButton onClick={handleClick}>
      <FontAwesomeIcon icon={faBars} color="white"/>
    </IconButton>
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={() => {history.push(`/app/project/${sessionId}/report`)}} disabled={!sessionId}>Results Summary</MenuItem>
      <MenuItem onClick={() => {history.push("/logout")}}>Logout</MenuItem>
    </Menu>
  </div>);
}

export default HamburgerMenu