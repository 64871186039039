import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const SURVEY_DETAILS_QUERY = gql`
query SurveyDetails($survey_id: uuid!) {
  SUR_survey_by_pk(id: $survey_id) {
    id
    name
    internal_name
    description
    qr_codes {
      id
      code
    }
    questions(order_by: {sort_position: asc}) {
      id
      type
      name
      description
      required
      sort_position
      min_value
      max_value
      validation_mode
      validation_count
      validation_error_message
      options(order_by: {sort_position: asc}) {
        id
        sort_position
        text
      }
    }
  }
}
`;

export interface SurveyDetailsData {
  SUR_survey_by_pk: {
    id: string;
    name: string;
    internal_name: string;
    description: string | null;
    qr_codes: {
      id: string;
      code: string;
    }[];
    questions: {
      id: string;
      type: string;
      name: string;
      description: string | null;
      required: boolean;
      sort_position: number;
      min_value: number | null;
      max_value: number | null;
      validation_mode: string | null;
      validation_count: number | null;
      validation_error_message: string | null;
      options: {
        id: string;
        sort_position: number;
        text: string;
      }[];
    }[]
  }
}

export interface SurveyDetailsVariables {
  survey_id: string;
}

export function useSurveyDetailsQuery(
  options?:
    | QueryHookOptions<
        SurveyDetailsData,
        SurveyDetailsVariables
      >
    | undefined
) {
  return useQuery<
    SurveyDetailsData,
    SurveyDetailsVariables
  >(SURVEY_DETAILS_QUERY, options);
}
