import { gql, useApolloClient } from "@apollo/client";
import { useCallback } from "react";

interface ScanQueryData {
  qr_code?: [
    {
      object_type: string;
      sample?: {
        id: string;
        project_id: string;
      };
      project?: {
        id: string;
      };
      lexicon?: {
        id: string;
        projects: {
          id: string;
        }[];
      };
      descriptor?: {
        id: string;
      };
      survey?: {
        id: string;
      }
    }
  ];
}

interface ScanQueryVars {
  qrCode: string;
}

const SCAN_QUERY = gql`
  query GetObjectWithQrCode($qrCode: String) {
    qr_code(where: { code: { _eq: $qrCode } }) {
      object_type
      sample {
        id
        project_id
      }
      project {
        id
      }
      lexicon {
        id
        projects {
          id
        }
      }
      descriptor {
        id
      }
      survey {
        id
      }
    }
  }
`;

function useLazyQRQuery() {
  const client = useApolloClient();
  return useCallback(
    (variables: ScanQueryVars) =>
      client.query<ScanQueryData, ScanQueryVars>({
        query: SCAN_QUERY,
        variables: variables,
      }),
    [client]
  );
}

export enum QRCodeType {
  project,
  lexicon,
  sample,
  descriptor,
  survey
}

export interface QRCodeResult {
  code: string;
  type: QRCodeType;
  objectId?: string;
  projectId?: string;
}

export default function useQRCode() {
  const runQuery = useLazyQRQuery();

  const readCode = async (code: string): Promise<QRCodeResult> => {
    const { data, error } = await runQuery({ qrCode: code });
    if (!data?.qr_code?.length) {
      throw error;
    }
    switch (data.qr_code[0].object_type) {
      case "project":
        return {
          code: code,
          type: QRCodeType.project,
          objectId: data.qr_code[0].project!.id,
          projectId: data.qr_code[0].project!.id,
        };
      case "lexicon":
        return {
          code: code,
          type: QRCodeType.lexicon,
          objectId: data.qr_code[0].lexicon!.id,
        };
      case "sample":
        return {
          code: code,
          type: QRCodeType.sample,
          objectId: data.qr_code[0].sample!.id,
          projectId: data.qr_code[0].sample!.project_id,
        };
      case "descriptor":
        return {
          code: code,
          type: QRCodeType.descriptor,
          objectId: data.qr_code[0].descriptor!.id,
        };
      case "survey":
        return {
          code: code,
          type: QRCodeType.survey,
          objectId: data.qr_code[0].survey!.id
        }
      default:
        throw new Error("unknown type");
    }
  };

  return readCode;
}
