import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useProjectSurveyId } from "../../../queries/GetProjectSurveyId";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useProjectMixRatingsSummary } from "../../../queries/GetProjectMixRatingsSummary";
import { useProjectMixRatingsCsv } from "../../../queries/GetProjectMixRatingsCsv";
import { downloadCsv } from "../../../../util/DownloadCsv";
import {
  ProjectSurveyResultsData,
  useProjectSurveyResultsQuery,
} from "../../../queries/GetProjectSurveyResults";
import ResultsTable, {
  ResultsTableProps,
} from "../../surveys/components/ResultsTable";
import { mapApollo } from "../../../../util/ApolloLoadable";
import ApolloRouter from "../../../../util/ApolloRouter";
import { useProjectSurveyResultsCsv } from "../../../queries/GetProjectSurveyResultsCsv";

export interface ResultsTabProps {
  sessionId: string;
}

export function ResultsTab(p: ResultsTabProps) {
  const query = useProjectSurveyId({ variables: { id: p.sessionId } });

  return (
    <Box>
      <MemoryRatingResults sessionId={p.sessionId} />
      <SurveyResults sessionId={p.sessionId} />
    </Box>
    // <ApolloRouter
    //   loadable={query}
    //   ready={(data) => (
    //     <SurveyResults surveyId={data.project_by_pk!.survey_id} />
    //   )}
    // />
  );
}

interface MemoryRatingResultsProps {
  sessionId: string;
}

function MemoryRatingResults(p: MemoryRatingResultsProps) {
  const summary = useProjectMixRatingsSummary({
    variables: { project_id: p.sessionId },
    pollInterval: 5000,
  });
  const getCsv = useProjectMixRatingsCsv({
    skip: true,
    variables: { project_id: p.sessionId },
  });

  const handleDownload = () => {
    getCsv.refetch().then((resp) => {
      if (resp.data.MIX_rating_csv?.length) {
        const data = resp.data.MIX_rating_csv[0].csv;
        downloadCsv(data, `Memory Ratings for session ${p.sessionId}.csv`);
      }
    });
  };

  const ratings = summary.data?.MIX_rating || [];
  return (
    <Box>
      <Box display="flex" justifyContent={"space-between"} marginBottom={1}>
        <Typography variant="h5">Mix Rating Results</Typography>
        <Button
          size="small"
          variant="contained"
          endIcon={<GetAppIcon />}
          onClick={handleDownload}
        >
          Export memory ratings (.csv)
        </Button>
      </Box>
      <TableContainer
        component={Paper}
        style={{ height: "300px", overflowY: "auto" }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>Mix</TableCell>
              <TableCell>Rating</TableCell>
              <TableCell>Comments</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ratings.map((rating) => (
              <TableRow key={rating.id}>
                <TableCell>{new Date(rating.updated_at).toLocaleString()}</TableCell>
                <TableCell>{rating.mix.name}</TableCell>
                <TableCell>
                  {rating.rating == null ? "" : String(rating.rating)}
                </TableCell>
                <TableCell>
                  {rating.comments == null ? "" : rating.comments}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

interface SurveyResultsProps {
  sessionId: string;
}

function SurveyResults(p: SurveyResultsProps) {
  const query = useProjectSurveyResultsQuery({
    variables: { project_id: p.sessionId },
    pollInterval: 5000,
  });
  const getCsv = useProjectSurveyResultsCsv({
    skip: true,
    variables: { project_id: p.sessionId },
  });

  const handleDownload = () => {
    getCsv.refetch().then((resp) => {
      const data = resp.data.project_by_pk.survey.response_csv?.csv;
      if (data) {
        downloadCsv(data, `Survey Results for session ${p.sessionId}.csv`);
      }
    });
  };

  return (
    <Box marginTop={4}>
      <Box display="flex" justifyContent={"space-between"} marginBottom={1}>
        <Typography variant="h5">Survey Results</Typography>
        <Button
          size="small"
          variant="contained"
          endIcon={<GetAppIcon />}
          onClick={handleDownload}
        >
          Export survey responses (.csv)
        </Button>
      </Box>
      <ApolloRouter
        loadable={mapApollo(query, mapQueryToTableData)}
        ready={(data) => <ResultsTable {...data} />}
      />
    </Box>
  );
}

function mapQueryToTableData(
  data: ProjectSurveyResultsData
): ResultsTableProps {
  return {
    surveyId: data.project_by_pk.survey.id,
    questions: data.project_by_pk.survey.questions.map((q) => ({
      questionId: q.id,
      text: q.name,
      isMultiResponse: q.type.startsWith("select")
    })),
    responses: data.project_by_pk.survey.responses.map((r) => ({
      responseId: r.id,
      date: new Date(r.created_at),
      answers: r.question_responses.map((q) => ({
        answerId: q.id,
        questionId: q.question_id,
        value: q.value,
      })),
    })),
  };
}
