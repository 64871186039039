import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const UPDATE_OPTION_MUTATION = gql`
mutation UpdateOption($option_id: uuid!, $text: String!) {
  update_SUR_survey_question_option_by_pk(pk_columns: {id: $option_id}, _set: {text: $text}) {
    id
    question_id
    sort_position
    text
  }
}
`;

export interface UpdateOptionData {
  update_SUR_survey_question_option_by_pk: {
    id: string,
    question_id: string,
    sort_position: number,
    text: string
  }
}

export interface UpdateOptionVariables {
  option_id: string;
  text: string;
}

export function useUpdateOptionMutation(
  options?:
    | MutationHookOptions<UpdateOptionData, UpdateOptionVariables>
    | undefined
) {
  return useMutation<UpdateOptionData, UpdateOptionVariables>(
    UPDATE_OPTION_MUTATION,
    options
  );
}
