import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const query = gql`
query GetProjectSurveyResults($project_id: uuid!) {
  project_by_pk(id: $project_id) {
    survey {
      id
      responses(order_by: {created_at: desc}) {
        id
        created_at
        question_responses {
          id
          question_id
          value
        }
      }
      questions {
        id
        name
        type
      }
    }
  }
}

`;

export interface ProjectSurveyResultsData {
  project_by_pk: {
    survey: {
      id: string;
      responses: {
        id: string;
        created_at: string;
        question_responses: {
          id: string;
          question_id: string;
          value: string | null;
        }[];
      }[];
      questions: {
        id: string;
        name: string;
        type: string;
      }[];
    }
  };
}

export interface ProjectSurveyResultsVariables {
  project_id: string;
}

export function useProjectSurveyResultsQuery(
  options?:
    | QueryHookOptions<ProjectSurveyResultsData, ProjectSurveyResultsVariables>
    | undefined
) {
  return useQuery<ProjectSurveyResultsData, ProjectSurveyResultsVariables>(
    query,
    options
  );
}
