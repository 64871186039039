import React, { useEffect } from "react";

interface DeferredComponentProps {
  children: React.ReactNode;
}

export default function DeferredComponent(
  props: DeferredComponentProps
): JSX.Element {
  const [shouldRender, setShouldRender] = React.useState(false);
  useEffect(() => {
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        setShouldRender(true);
      });
    });
  }, []);

  return (
    <React.Fragment>{shouldRender ? props.children : null}</React.Fragment>
  );
}
