import React, { useState } from "react";
import { css } from "@emotion/react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@material-ui/core";
import { gql, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";

const QUERY = gql`
query GetUsers {
  app_user(order_by: {disabled: asc, display_name: asc}) {
    id
    display_name
    is_admin
    secret
  }
}
`

interface QueryData {
  app_user: [{
    id: string;
    display_name: string;
    is_admin: boolean;
    secret: string;
  }
  ]
}

interface SecretHiderProps {
  secret: string;
}

const SecretHider = (p: SecretHiderProps) => {
  const [reveal, setReveal] = useState(false);

  const handleOnClick = () => {
    setReveal(current => !current)
  }

  if (reveal) {
    return <span onDoubleClick={handleOnClick}>{p.secret}</span>
  } else {
    return <span onDoubleClick={handleOnClick} css={css`user-select: none; font-style: italic`}>Double click to reveal</span>
  }
}

const UserList = () => {
  const history = useHistory();
  const { data } = useQuery<QueryData>(QUERY);
  const handleCreateNewClicked = () => {
    history.push("/admin/users/new")
  }

  return (<TableContainer component={Paper}>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Role</TableCell>
          <TableCell>Secret</TableCell>
          <TableCell
                css={css`
                width: 100px;
                `}
              >
                <Button
                fullWidth
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleCreateNewClicked}
                >
                  Create
                </Button>
              </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data?.app_user.map(user => {
          return (<TableRow key={user.id}>
            <TableCell component="th" scope="row">
              {user.display_name}
            </TableCell>
            <TableCell>{user.is_admin ? "Admin" : "Regular User"}</TableCell>
            <TableCell><SecretHider secret={user.secret}/></TableCell>
            <TableCell></TableCell>
          </TableRow>)
          })
        }
      </TableBody>
    </Table>
  </TableContainer>);
};

export default UserList;
