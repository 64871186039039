import { gql, QueryHookOptions, useQuery } from "@apollo/client";

const DescriptorName = gql`
  query DescriptorName($descriptor_id: uuid!) {
    descriptor_by_pk(id: $descriptor_id) {
      id
      name
    }
  }
`;

interface DescriptorNameVars {
  descriptor_id: string;
}

interface DescriptorNameData {
  descriptor_by_pk: {
    id: string;
    name: string;
  };
}

export default function useDescriptorName(
  descriptorId: string,
  options?: QueryHookOptions<DescriptorNameData, DescriptorNameVars>
) {
  return useQuery<DescriptorNameData, DescriptorNameVars>(DescriptorName, {
    variables: { descriptor_id: descriptorId, ...options?.variables },
    ...options,
  });
}
