import React, { ReactNode } from "react";
import { css } from "@emotion/react";
import {
  FontAwesomeIcon,
} from "@fortawesome/react-fontawesome";
import {
  IconProp,
} from "@fortawesome/fontawesome-svg-core";
import { faQrcode, faVial } from "@fortawesome/free-solid-svg-icons";
import { faCommentAlt, faSmileBeam } from "@fortawesome/free-regular-svg-icons";
import { useHistory } from "react-router-dom";


const cssScanButton = css`
  color: white;
  margin: 2px 10px;
`;

const cssScanButtonIcon = css`
  background-color: rgb(139, 156, 7);
  border-radius: 100px;
  font-size: 30px;
  margin: auto;
  padding: 10px;
`;

interface ScanButtonProps {
  onClick: () => void;
}

const ScanButton = (p: ScanButtonProps) => {
  return (
    <div css={cssScanButton} onClick={p.onClick}>
      <FontAwesomeIcon icon={faQrcode} css={cssScanButtonIcon} />
    </div>
  );
};

const cssLinkButton = css`
  color: white;
  text-align: center;
  padding: 2px;
`;

const cssLinkButtonText = css`
  font-size: 14px;
`;

interface LinkButtonProps {
  icons: IconProp[];
  onClick: () => void;
  children?: ReactNode;
}

const LinkButton = (p: LinkButtonProps) => {
  return (
    <div css={cssLinkButton} onClick={p.onClick}>
      <span
        className="fa-layers fa-fw"
        css={css`
          font-size: 28px;
        `}
      >
        {p.icons.map((icon, i) => (
          <FontAwesomeIcon
            icon={icon}
            css={css`
              font-size: ${28 - i * 12}px;
              bottom: 4px;
            `}
          />
        ))}
      </span>
      <div css={cssLinkButtonText}>{p.children}</div>
    </div>
  );
};

const cssParent = css`
  background-color: #222222;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: max-content;
  gap: 0px 0px;
  grid-template-areas: ". . .";
  padding: 0px 10px;
  user-select: none;
`;

export default function Tabs (p: { projectId?: string }) {
  const history = useHistory();

  if (p.projectId) {
    return (
      <div css={cssParent}>
        <LinkButton
          icons={[faVial]}
          onClick={() => history.replace(`/app/project/${p.projectId}/samples`)}
        >
          Evaluate
        </LinkButton>
        <div/>
        {/* <ScanButton
          onClick={() => history.push(`/app/project/${p.projectId}/scan`)}
        /> */}
        <LinkButton
          icons={[faCommentAlt, faSmileBeam]}
          onClick={() => history.replace(`/app/project/${p.projectId}/lexicon`)}
        >
          Speak
        </LinkButton>
      </div>
    );
  } else {
    return (
      <div css={cssParent}>
        <div />
        <ScanButton onClick={() => history.push(`/app/scan`)} />
        <div />
      </div>
    );
  }
};
