import React, { useState } from "react";
import { css } from "@emotion/react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import crs from "crypto-random-string";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDice } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import { useCreateUser } from "../../queries/CreateUser";

const styles = {
  container: css`
    height: calc(100vh - 160px);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    gap: 1em 1em;
  `,
  card: css`
    max-width: 400px;
  `,
  access: css`
    display: flex;
  `,
};

interface CreateUserProps {}

export default function CreateUser(p: CreateUserProps) {
  const history = useHistory();
  const [createUser] = useCreateUser();
  const [displayName, setDisplayName] = useState("");
  const [secret, setSecret] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const valid = displayName.length >= 2 && secret.length >= 4;

  const handleGenerateSecret = () => {
    setSecret(
      crs({ length: 8, characters: "abcdefghjkmnpqrstuvwzyz23456789" })
    );
  };
  const handleCreate = () => {
    createUser({
      variables: {
        display_name: displayName,
        secret: secret,
        is_admin: isAdmin,
      },
      refetchQueries: ["GetUsers"],
    }).then(() => {
      history.replace("/admin/users");
    });
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div css={styles.container}>
      <Card css={styles.card}>
        <CardContent>
          <Typography variant="h5">Create new user</Typography>

          <TextField
            fullWidth
            label="Display name"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <div css={styles.access}>
            <TextField
              fullWidth
              label="Access code"
              value={secret}
              onChange={(e) => setSecret(e.target.value)}
            />
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={handleGenerateSecret}
            >
              <FontAwesomeIcon icon={faDice} />
            </IconButton>
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAdmin}
                onChange={(_, checked) => setIsAdmin(checked)}
                name="checkedB"
                color="primary"
              />
            }
            label="Make administrator"
          />
        </CardContent>
      </Card>
      <div />
      <Footer>
        <div>
          <Box component="span" m={1}>
            <Button variant="contained" onClick={handleBack}>
              Cancel
            </Button>
          </Box>
          <Box component="span">
            <Button variant="contained" color="primary" onClick={handleCreate} disabled={!valid}>
              Create User
            </Button>
          </Box>
        </div>
      </Footer>
    </div>
  );
}
