import React, { useState } from "react";
import { css } from "@emotion/react";
import { Descriptor } from "./Lexicon";
import { gql, useMutation, useQuery } from "@apollo/client";
import StarRating from "react-star-rating-component";
import { useSession } from "../../SessionContext";
import Associations from "../mobile/descriptor/Associations";
import Evaluation from "../mobile/descriptor/Evaluation";
import { Box, Button, Input, Snackbar, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const cssRatingPanel = css`
  background-color: rgb(234, 234, 234);
  color: black;
  padding: 10px 40px 10px 40px;
`;

const cssRatingPanelStars = css`
  font-size: 40px;
  text-align: center;
`;

interface RatingPanelProps {
  name?: string;
  rating?: number;
  comments?: string;
  onRatingChange?: (newRating: number) => void;
  onCommentChange?: (newComment: string) => void;
}

const RatingPanel = (p: RatingPanelProps) => {
  const [c, setC] = useState(p.comments || '');
  const [open, setOpen] = useState(false);

  const handleSave = () => {
    if (p.onCommentChange) { p.onCommentChange(c) }
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <div css={cssRatingPanel}>
      <Typography align="center" variant="h6">
        Personal Rating
      </Typography>
      <div css={cssRatingPanelStars}>
        <StarRating
          name="rating"
          value={p.rating || 0}
          starCount={5}
          onStarClick={p.onRatingChange}
        />
      </div>
      <Typography align="center" variant="h6">
        Personal Associations
      </Typography>

        <Box
          marginLeft="10px"
          marginRight="10px"
          css={css`
            background-color: white;
            font-size: 24px;
          `}
        >
          <Input
            disableUnderline
            multiline
            rows={6}
            fullWidth
            css={css`
              padding: 10px;
            `}
            value={c}
            onChange={e => {setC(e.target.value)}}
          />
        </Box>
        <Box margin="10px">
        <Button fullWidth variant="contained" color="primary" onClick={handleSave}>Save Feedback</Button>
      </Box>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Feedback saved
        </Alert>
      </Snackbar>
    </div>
  );
};

const cssParent = css`
  color: white;
`;

interface DescriptorDetailsProps {
  descriptorId: string;
}

interface QueryData {
  descriptor_by_pk?: {
    name: string;
    image: string;
    description: string;
    evaluation_instruction?: string;
    ratings: [{ comments?: string; rating?: number }];
    color_name?: string;
    color_value?: string;
  };
}

interface QueryVars {
  id: string;
  userId: string;
}

const QUERY = gql`
  query GetDescriptorDetails($id: uuid!, $userId: uuid = "") {
    descriptor_by_pk(id: $id) {
      image
      description
      evaluation_instruction
      ratings(limit: 1, where: { app_user_id: { _eq: $userId } }) {
        comments
        rating
      }
      name
      color_name
      color_value
    }
  }
`;

const RATE_MUT = gql`
mutation MyMutation(
  $appUserId: uuid!
  $descriptorId: uuid!
  $comments: String
  $rating: Int
) {
  insert_descriptor_rating_one(
    object: {
      descriptor_id: $descriptorId
      app_user_id: $appUserId
      rating: $rating
      comments: $comments
    }
    on_conflict: {
      constraint: descriptor_evaluation_descriptor_id_app_user_id_key
      update_columns: [rating, comments]
    }
  ) {
    comments
    rating
    id
  }
}
`;

export default function DescriptorDetails(p: DescriptorDetailsProps) {
  const { userId } = useSession();
  const { loading, error, data, refetch } = useQuery<QueryData, QueryVars>(
    QUERY,
    {
      variables: { id: p.descriptorId, userId },
      
    }
  );
  const [_updateRating] = useMutation(RATE_MUT);
  const updateRating = (rating?: number, comments?: string) => {
    _updateRating({
      variables: {
        descriptorId: p.descriptorId,
        appUserId: userId,
        comments: comments,
        rating: rating,
      },
    }).then(() => refetch());
  };

  const rating = data?.descriptor_by_pk?.ratings?.length
    ? data?.descriptor_by_pk?.ratings[0].rating
    : undefined;
  
  const [commentsLocal, setCommentsLocal ]= useState('');
  const comments = commentsLocal ? commentsLocal : data?.descriptor_by_pk?.ratings?.length
    ? data?.descriptor_by_pk?.ratings[0].comments
    : undefined;

  const handleRatingChange = (rating: number) => {
    updateRating(rating, comments);
  };
  const handleCommentsChange = (newComments: string) => {
    setCommentsLocal(newComments);
    updateRating(rating, newComments);
  }

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error...</div>;
  }
  if (!data || !data.descriptor_by_pk) {
    return <div>No data...</div>;
  }
  return (
    <div css={cssParent}>
      <Descriptor name={data.descriptor_by_pk.name} description={data.descriptor_by_pk.description} image={data.descriptor_by_pk.image}/>
      <Evaluation evaluation={data.descriptor_by_pk.evaluation_instruction!}/>
      <RatingPanel
        name={data.descriptor_by_pk.name}
        rating={rating}
        comments={comments}
        onRatingChange={handleRatingChange}
        onCommentChange={handleCommentsChange}
      />
      <Associations image={data.descriptor_by_pk.image} color={data.descriptor_by_pk.color_value!} colorName={data.descriptor_by_pk.color_name!}/>
    </div>
  );
};
