import { gql, MutationHookOptions, useMutation } from "@apollo/client";

const EvaluateSample = gql`
mutation EvaluateSample($sample_id: uuid!, $app_user_id: uuid!, $liking_score: Int, $concept_fit_score: Int, $comments: String) {
  insert_evaluation_one(object: {sample_id: $sample_id, app_user_id: $app_user_id, liking_score: $liking_score, concept_fit_score: $concept_fit_score, comments: $comments}, on_conflict: {constraint: evaluation_app_user_id_sample_id_key, update_columns: [liking_score, concept_fit_score, comments]}) {
    id
    liking_score
    concept_fit_score
    comments
  }
}
`;

interface EvaluateSampleVars {
  sample_id: string;
  app_user_id: string;
  liking_score?: number;
  concept_fit_score?: number;
  comments?: string;
}

interface EvaluateSampleData {
  insert_evaluation_one: {
    id: string;
    liking_score?: number;
    concept_fit_score?: number;
    comments?: string;
  }
}


export default function useEvaluateSample(options?: MutationHookOptions<EvaluateSampleData, EvaluateSampleVars>) {
  return useMutation<EvaluateSampleData, EvaluateSampleVars>(EvaluateSample, options);
}