import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const getProjectSurveyIdQuery = gql`
query GetProjectSurveyId($id: uuid!) {
  project_by_pk(id: $id) {
    id
    survey_id
  }
}
`;

export interface GetProjectSurveyIdVariables {
  id: string;
}

export interface GetProjectSurveyIdData {
  project_by_pk?: {
    id: string;
    survey_id: string;
  };
}

export function useProjectSurveyId(
  options?:
    | QueryHookOptions<
        GetProjectSurveyIdData,
        GetProjectSurveyIdVariables
      >
    | undefined
) {
  return useQuery<
    GetProjectSurveyIdData,
    GetProjectSurveyIdVariables
  >(getProjectSurveyIdQuery, options);
}
