import React, { useEffect } from "react";
import useLogin from "./useLogin";

const LogOut = () => {
  const {logout} = useLogin();

  useEffect(() => {
    logout();
  })

  return <div>Logging out...</div>
}

export default LogOut;
