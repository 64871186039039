import React from "react";
import { css } from "@emotion/react";
import logoImage from "./g.png";

const styles = {
  container: css`
    margin: 5px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
  `,
  image: css`
    height: 30px;
    margin-right: 5px;
  `,
  textAI: css`
    color: rgb(180, 212, 71);
    font-size: 24px;
    font-weight: 600;
  `,
  textRomi: css`
    color: rgb(91, 128, 41);
    font-size: 24px;
    font-weight: 400;
  `,
}

const _ = () => {
  return (<div css={styles.container}>
      <img css={styles.image} src={logoImage} alt="AromaSniff Logo" />
      <span css={styles.textAI}>Aroma</span>
      <span css={styles.textRomi}>Sniff™</span>
  </div>);
}

export default _