import React, { useState } from "react";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import Heart from "./Heart";
import DescriptorDesire from "./DescriptorDesire";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import WordCloud, {
  WordCloudWord,
} from "../../admin/pages/components/WordCloud";
import { useHistory } from "react-router";
import _ from "lodash";

export interface SampleData {
  id: string;
  name: string;
  ranks: {
    conceptFit?: number;
    justAboutRight?: number;
    liking?: number;
  };
  furtherIncreaseDescriptors: string[];
  furtherReduceDescriptors: string[];
  words: WordCloudWord[];
}

export interface ProjectSummaryTableProps {
  projectId?: string;
  evaluators: number;
  samples: SampleData[];
  maximumRanks: {
    conceptFit?: number;
    justAboutRight?: number;
    liking?: number;
  };
  showLinks?: boolean;
}

type SortBy = "name" | "liking" | "concept" | "profile";

function RankHeart(p: { rank?: number }) {
  if (p.rank == null) {
    return <span>-</span>;
  }
  return (
    <span>
      <Heart intensity={(p.rank - 1) / 10} />
    </span>
  );
}

function getSortedSamples(
  samples: SampleData[],
  sortBy: SortBy,
  ascending: boolean
) {
  let sorted;
  switch (sortBy) {
    case "name":
      sorted = _.sortBy(samples, (s) => s.name);
      break;
    case "liking":
      sorted = _.sortBy(samples, (s) => s.ranks.liking);
      break;
    case "concept":
      sorted = _.sortBy(samples, (s) => s.ranks.conceptFit);
      break;
    case "profile":
      sorted = _.sortBy(samples, (s) => s.ranks.justAboutRight);
      break;
  }

  if (!ascending) {
    return _.reverse(sorted);
  } else {
    return sorted;
  }
}

function dir(ascending: boolean) {
  if (ascending) {
    return "asc";
  }
  return "desc";
}

export function ProjectSummaryTable(p: ProjectSummaryTableProps) {
  const history = useHistory();

  const [sortBy, setSortBy] = useState("name" as SortBy);
  const [sortAscending, setSortAscending] = useState(true);

  const samples = getSortedSamples(p.samples, sortBy, sortAscending);

  const handleChangeSort = (newSortBy: SortBy) => {
    if (newSortBy === sortBy) {
      setSortAscending((ascending) => !ascending);
    } else {
      setSortBy(newSortBy);
      setSortAscending(true);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell onClick={() => handleChangeSort("name")}>
              <TableSortLabel
                active={sortBy === "name"}
                direction={dir(sortAscending)}
              >
                Sample
              </TableSortLabel>
            </TableCell>
            <TableCell
              align="center"
              onClick={() => handleChangeSort("liking")}
            >
              <TableSortLabel
                active={sortBy === "liking"}
                direction={dir(sortAscending)}
              >
                Liking
              </TableSortLabel>
            </TableCell>
            <TableCell
              align="center"
              onClick={() => handleChangeSort("concept")}
            >
              <TableSortLabel
                active={sortBy === "concept"}
                direction={dir(sortAscending)}
              >
                Concept
              </TableSortLabel>
            </TableCell>
            <TableCell
              align="center"
              onClick={() => handleChangeSort("profile")}
            >
              <TableSortLabel
                active={sortBy === "profile"}
                direction={dir(sortAscending)}
              >
                Profile
              </TableSortLabel>
            </TableCell>
            <TableCell>Further Reduce</TableCell>
            <TableCell>Further Increase</TableCell>
            <TableCell>Wordcloud</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {samples.map((sample) => (
            <TableRow key={sample.id}>
              <TableCell>
                {p.showLinks ? (
                  <IconButton
                    onClick={() =>
                      history.push(
                        `/admin/sessions/${p.projectId}/samples/${sample.id}/report`
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" />
                  </IconButton>
                ) : null}
                <span>{sample.name}</span>
              </TableCell>
              <TableCell align="center">
                <RankHeart rank={sample.ranks.liking} />
              </TableCell>
              <TableCell align="center">
                <RankHeart rank={sample.ranks.conceptFit} />
              </TableCell>
              <TableCell align="center">
                <RankHeart rank={sample.ranks.justAboutRight} />
              </TableCell>
              <TableCell>
                <DescriptorDesire
                  descriptors={sample.furtherReduceDescriptors}
                  prefix={
                    <Box component="span" marginRight={1}>
                      <FontAwesomeIcon icon={faAngleDown} />
                    </Box>
                  }
                />
              </TableCell>
              <TableCell>
                <DescriptorDesire
                  descriptors={sample.furtherIncreaseDescriptors}
                  prefix={
                    <Box component="span" marginRight={1}>
                      <FontAwesomeIcon icon={faAngleUp} />
                    </Box>
                  }
                />
              </TableCell>
              <TableCell align="center">
                {sample.words.length > 0 ? (
                  <WordCloud words={sample.words} size={[225, 150]} key={sample.id}/>
                ) : (
                  <span>-</span>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box width="100%" alignItems="center" textAlign="center" padding={1}>
        <Typography>Based on feedback from {p.evaluators} tasters.</Typography>
      </Box>
    </TableContainer>
  );
}
