import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const CREATE_SURVEY_MUTATION = gql`
  mutation CreateSurvey(
    $internal_name: String!
    $name: String!
    $description: String = null
  ) {
    insert_SUR_survey_one(
      object: {
        internal_name: $internal_name
        name: $name
        description: $description
        qr_codes: { data: {} }
      }
    ) {
      id
    }
  }
`;

export interface CreateSurveyData {
  insert_SUR_survey_one: {
    id: string;
  };
}

export interface CreateSurveyVariables {
  internal_name: string;
  name: string;
  description: string | null;
}

export function useCreateSurvey(
  options?: MutationHookOptions<CreateSurveyData, CreateSurveyVariables> | undefined
) {
  return useMutation<CreateSurveyData, CreateSurveyVariables>(
    CREATE_SURVEY_MUTATION,
    options
  );
}
