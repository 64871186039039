import React from "react";

interface DescriptorDesireProps {
  descriptors: string[];
  prefix?: JSX.Element;
}

export default function DescriptorDesire(p: DescriptorDesireProps) {
  return (
    <span>
      {p.descriptors.length === 0
        ? "-"
        : p.descriptors.sort().map((text, index) => (
            <React.Fragment key={index}>
              {p.prefix}
              <span>{text}</span>
              {index !== p.descriptors.length - 1 ? <br /> : null}
            </React.Fragment>
          ))}
    </span>
  );
}
