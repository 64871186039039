import { MenuItem, Select, Typography } from "@material-ui/core";
import React from "react";
import QInputProps from "./QInputProps";

export default function QSelectDropdown(p: QInputProps) {
  if (p.options == null || p.options.length === 0) {
    return <Typography>No options available.</Typography>;
  }
  return (
    <Select
      fullWidth
      value={p.value || ""}
      onChange={(e) => p.onValueChange(e.target.value as string)}
      variant="outlined"
    >
      {p.options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}
