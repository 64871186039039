import { gql, QueryHookOptions, useQuery } from "@apollo/client";

const ProjectName = gql`
  query ProjectName($project_id: uuid!) {
    project_by_pk(id: $project_id) {
      id
      name
    }
  }
`;

interface ProjectNameVars {
  project_id: string;
}

interface ProjectNameData {
  project_by_pk: {
    id: string;
    name: string;
  };
}

export default function useProjectName(
  projectId: string,
  options?: QueryHookOptions<ProjectNameData, ProjectNameVars>
) {
  return useQuery<ProjectNameData, ProjectNameVars>(ProjectName, {
    variables: { project_id: projectId, ...options?.variables },
    ...options,
  });
}
