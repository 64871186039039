import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const query = gql`
query GetProjectSummaryOfMixRatings($project_id: uuid!) {
  MIX_rating(where: {mix: {project_id: {_eq: $project_id}}}, order_by: {updated_at: desc}) {
    id
    mix {
      id
      name
    }
    rating
    comments
    updated_at
  }
}
`;

export interface GetProjectMixRatingsSummaryVariables {
  project_id: string;
}

export interface GetProjectMixRatingsSummaryData {
  MIX_rating?: {
    id: string;
    mix: {
      id: string;
      name: string;
    }
    rating?: number;
    comments?: string;
    updated_at: string;
  }[];
}

export function useProjectMixRatingsSummary(
  options?:
    | QueryHookOptions<
        GetProjectMixRatingsSummaryData,
        GetProjectMixRatingsSummaryVariables
      >
    | undefined
) {
  return useQuery<
    GetProjectMixRatingsSummaryData,
    GetProjectMixRatingsSummaryVariables
  >(query, options);
}
