import React from "react";
import { css } from "@emotion/react";
import DescriptorRating from "./DescriptorRating";

const styles = {
  container: css`
    display: grid;
    padding: 10px;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 5px 5px;
  `,
};

interface DescriptorGridProps {
  descriptors: {
    id: string;
    name: string;
    rating: number;
  }[];
  onRatingChange: (
    descriptorId: string,
    newRating: number,
    currentRating: number
  ) => void;
}

export default function DescriptorGrid(p: DescriptorGridProps) {
  return (
    <div css={styles.container}>
      {p.descriptors.map((d) => (
        <DescriptorRating
          key={d.id}
          name={d.name}
          rating={d.rating}
          onRatingChange={(newRating, currentRating) =>
            p.onRatingChange(d.id, newRating, currentRating)
          }
        />
      ))}
    </div>
  );
}
