import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const SURVEY_RESPONSE_CSV_QUERY = gql`
  query SurveyResponseCsv($survey_id: uuid!) {
    SUR_survey_by_pk(id: $survey_id) {
      response_csv {
        csv
      }
    }
  }
`;

export interface SurveyResponseCsvData {
  SUR_survey_by_pk: {
    response_csv: {
      csv: string;
    };
  };
}

export interface SurveyResponseCsvVariables {
  survey_id: string;
}

export function useSurveyResponseCsvQuery(
  options?:
    | QueryHookOptions<SurveyResponseCsvData, SurveyResponseCsvVariables>
    | undefined
) {
  return useQuery<SurveyResponseCsvData, SurveyResponseCsvVariables>(
    SURVEY_RESPONSE_CSV_QUERY,
    options
  );
}
