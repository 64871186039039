import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const getChannelQuery = gql`
query GetChannel($project_id: uuid!, $index: Int!) {
  project_channel(where: {project_id: {_eq: $project_id}, channel_index: {_eq: $index}}) {
    id
    name
    image
    description
    channel_index
    enabled
    flow_value
    development_code
    group_code
    solvent
    concentration
    notes
  }
}
`;

export interface GetChannelVariables {
  project_id: string;
  index: number;
}

export interface GetChannelData {
  project_channel: {
    id: string;
    name: string;
    image: string;
    description: string;
    channel_index: number;
    enabled: boolean;
    flow_value: number;
    development_code: string | null;
    group_code: string | null;
    solvent: string | null;
    concentration: string | null;
    notes: string | null;
  }[]
}

export function useChannelInfo(
  options?:
    | QueryHookOptions<
        GetChannelData,
        GetChannelVariables
      >
    | undefined
) {
  return useQuery<
    GetChannelData,
    GetChannelVariables
  >(getChannelQuery, options);
}
