import React, { useState } from "react";
import jwt_decode from 'jwt-decode'

const localStorageKey = "fu2_session_id"
const lexiconStorageKey = "fu2_lexicon_id"
const jwtStorageKey = "fu2_jwt"
const userIdStorageKey = "fu2_user_id"
const isAdminStorageKey = "fu2_is_admin"

const SessionContext = React.createContext({
  sessionId: '',
  lexiconId: '',
  jwt: '',
  userId: '',
  isAdmin: false,
  setSessionId: (val: string) => {},
  setLexiconId: (val: string) => {},
  setJwt: (val: string) => {}
});

const SessionProvider = (props: any) => {
  const [sessionId, _setSessionId] = useState(localStorage.getItem(localStorageKey));
  const [lexiconId, _setLexiconId] = useState(localStorage.getItem(lexiconStorageKey))
  const [jwt, _setJwt] = useState(localStorage.getItem(jwtStorageKey));
  const [userId, _setUserId] = useState(localStorage.getItem(userIdStorageKey))
  const [isAdmin, _setIsAdmin] = useState(localStorage.getItem(isAdminStorageKey) === "true");

  const setSessionId = (sessionId: string) => {
    if (sessionId) {
      localStorage.setItem(localStorageKey, sessionId)
    } else {
      localStorage.removeItem(localStorageKey)
    }
    _setSessionId(sessionId)
  }

  const setLexiconId = (lexiconId: string) => {
    if (lexiconId) {
      localStorage.setItem(lexiconStorageKey, lexiconId)
    } else {
      localStorage.removeItem(lexiconStorageKey)
    }
    _setLexiconId(lexiconId)
  }

  const setJwt = (jwt: string) => {
    if (jwt) {
      localStorage.setItem(jwtStorageKey, jwt)
      const decoded = jwt_decode(jwt) as any;
      localStorage.setItem(userIdStorageKey, decoded["sub"])
      _setUserId(decoded["sub"])
      const isAdmin = decoded["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"].includes("admin") as boolean;
      localStorage.setItem(isAdminStorageKey, isAdmin ? "true" : "false")
      _setIsAdmin(isAdmin);

    } else {
      localStorage.removeItem(jwtStorageKey)
      localStorage.removeItem(localStorageKey)
      localStorage.removeItem(lexiconStorageKey);
      localStorage.removeItem(userIdStorageKey)
      localStorage.removeItem(isAdminStorageKey);
    }
    _setJwt(jwt)
  }

  return <SessionContext.Provider
    value={{sessionId, lexiconId, jwt, userId, isAdmin, setSessionId, setLexiconId, setJwt}}
    {...props}
  />
}

const useSession = () => {
  const ctx = React.useContext(SessionContext);
  if (ctx === undefined) {
    throw new Error("useSession must be used within a SessionProvider")
  }
  return ctx;
}

const useUserId = () => {
  return localStorage.getItem(userIdStorageKey);
}

export { SessionProvider, SessionContext, useSession, useUserId }