import React, { useState } from "react";
import { css } from "@emotion/react";
import Logo from "../../components/Logo";
import Typography from "../../components/Typography";
import { Typography as MUITypography } from "@material-ui/core";
import useLogin from "./useLogin";
import { Alert } from "@material-ui/lab";

const cssParent = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
`;

const cssLogo = css`
  flex: initial;
  margin: 30px auto;
`;

const cssText = css`
  flex: initial;
`;

const cssForm = css`
  flex: initial;
  padding: 20px 0px;
  height: 80px;
`;

const cssInput = css`
  // height: 40px;
  padding: 5px;
  font-size: 30px;
`;

const cssButton = css`
  font-size: 30px;
  background: transparent;
  color: white;
  border-radius: 10px;
  border: 2px solid white;
  font-weight: medium;
  padding: 5px 10px;
  margin: 0px 20px;
`;

const cssGrow = css`
  flex: 1 1 auto;
`;

export interface LoginProps {
  then?: string;
}

export default function Login(p: LoginProps) {
  const [accessKey, setAccessKey] = useState("");
  const { login, state, error } = useLogin();
  const handleLoginClick = () => {
    login(accessKey);
  };

  return (
    <div css={cssParent}>
      {p?.then ? (
        <Alert severity="info">
          <MUITypography variant="body1">Please log in to access that code</MUITypography>
        </Alert>
      ) : null}
      <div css={cssLogo}>
        <Logo height={60} />
      </div>
      <div css={cssGrow} />
      <div css={cssText}>
        <Typography role="heading">Welcome!</Typography>
        <Typography role="paragraph">
          AromaSniff is only available by invitation.
        </Typography>
        <Typography role="paragraph">
          Enter your unique secret access key to continue.
        </Typography>
      </div>
      <div css={cssForm}>
        <input
          css={cssInput}
          type="text"
          placeholder="Access key"
          value={accessKey}
          onChange={(e) => setAccessKey(e.target.value)}
        />
        <button
          css={cssButton}
          onClick={handleLoginClick}
          disabled={state === "loading"}
        >
          Log in
        </button>
        {state === "loading" ? (
          <Typography center role="paragraph">
            Logging in...
          </Typography>
        ) : null}
        {state === "error" ? (
          <Typography center role="error">
            {error}
          </Typography>
        ) : null}
      </div>
      <div css={cssGrow} />
      <div css={cssGrow} />
    </div>
  );
};

// import React, { useState } from "react";
// import { css } from "@emotion/react";
// import { gql, useLazyQuery } from "@apollo/client";
// import { useSession } from "../SessionContext";

// const style = css`
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   background: transparent linear-gradient(160deg, #000000 0%, #4077F3 100%) 0% 0% no-repeat padding-box;
//   color: white;
// `

// interface LoginQueryData {
//   authenticate: {
//     jwt?: string;
//     success: boolean
//   }
// }

// interface LoginQueryVars {
//   secret: string;
// }

// const LOGIN_QUERY = gql`
// query Login($secret: String!) {
//   authenticate(secret: $secret) {
//     jwt
//     success
//   }
// }
// `;

// const Login = () => {
//   const { jwt, setJwt } = useSession();
//   const [ accessKey, setAccessKey ] = useState('');
//   const [ error, setError ] = useState('');
//   const onQueryComplete = (data: LoginQueryData) => {
//     if (data.authenticate.success) {
//       setJwt(data.authenticate.jwt!)
//     } else {
//       setError('Invalid login');
//     }
//   }
//   const [runLoginQuery, { loading, data }] = useLazyQuery<
//     LoginQueryData,
//     LoginQueryVars
//   >(LOGIN_QUERY, {onCompleted: onQueryComplete, onError: (e) => {setError(e.message)}, fetchPolicy: "no-cache"});

//   return <div css={style}>
//     <h1>Food Unite</h1>
//     <h2>Enter your access key to continue</h2>
//     <input type="text" value={accessKey} onChange={e => setAccessKey(e.target.value)}/>
//     <button disabled={!accessKey || accessKey.length < 4} onClick={() => {setError('Logging in...'); runLoginQuery({variables: {secret: accessKey}})}}>Enter</button>
//     <p>{error}</p>
//   </div>
// }

// export default Login
