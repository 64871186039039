import { useMutation } from "@apollo/client";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { Label, PlacedLabel } from "./label";
import {
  PrintCustomLabelsData,
  PrintCustomLabelsMutation,
  PrintCustomLabelsVariables,
} from "./queries";

function placeLabels(labels: Label[]): PlacedLabel[] {
  const columns = 7;
  return labels.map((label, index) => {
    return {
      ...label,
      row: Math.floor(index / columns) + 1,
      column: (index % columns) + 1,
    };
  });
}

interface PrintLabelsProps {
  labels: Label[];
}

export function PrintLabels(p: PrintLabelsProps) {
  const [working, setWorking] = useState(false);
  const [labels, setLabels] = useState(placeLabels(p.labels));
  const [createSheet] = useMutation<
    PrintCustomLabelsData,
    PrintCustomLabelsVariables
  >(PrintCustomLabelsMutation);

  function handleRowChanged(labelIndex: number, newValue: string) {
    try {
      const parsed = parseInt(newValue);
      const newLabels = [...labels];
      newLabels[labelIndex].row = parsed;
      setLabels(newLabels);
    } catch (ignored) {}
  }

  function handleColumnChanged(labelIndex: number, newValue: string) {
    try {
      const parsed = parseInt(newValue);
      const newLabels = [...labels];
      newLabels[labelIndex].column = parsed;
      setLabels(newLabels);
    } catch (ignored) {}
  }

  function handleClick() {
    setWorking(true);
    const vars = labels.map(l => ({
      qrCode: l.qrCode,
      caption: l.caption,
      row: l.row,
      column: l.column
    }))
    createSheet({ variables: { labels: vars } }).then((result) => {
      setWorking(false);
      const url = result.data?.print_custom_labels.url;
      if (url) {
        window.location.href = url;
      }
    }).then(e => {
      setWorking(false);
    });
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Row</TableCell>
            <TableCell>Column</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {labels.map((label, index) => (
            <TableRow key={`${index}${label.qrCode}`}>
              <TableCell>{label.type}</TableCell>
              <TableCell>{label.caption}</TableCell>
              <TableCell>{label.qrCode}</TableCell>
              <TableCell>
                {" "}
                <TextField
                  label="Row"
                  type="number"
                  value={label.row}
                  onChange={(e) => handleRowChanged(index, e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  label="Column"
                  type="number"
                  value={label.column}
                  onChange={(e) => handleColumnChanged(index, e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button color="primary" variant="contained" onClick={handleClick} disabled={working}>
        Print Label Sheet
      </Button>
    </TableContainer>
  );
}
