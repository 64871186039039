import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const createUserQuery = gql`
  mutation CreateUser(
    $display_name: String!
    $secret: String!
    $is_admin: Boolean = false
  ) {
    insert_app_user_one(
      object: {
        display_name: $display_name
        secret: $secret
        is_admin: $is_admin
      }
    ) {
      id
      created_at
    }
  }
`;

export interface CreateUserData {
  insert_app_user_one: {
    id: string;
    created_at: Date;
  };
}

export interface CreateUserVariables {
  display_name: string;
  secret: string;
  is_admin?: boolean;
}

export function useCreateUser(
  options?: MutationHookOptions<CreateUserData, CreateUserVariables> | undefined
) {
  return useMutation<CreateUserData, CreateUserVariables>(
    createUserQuery,
    options
  );
}
