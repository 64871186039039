import React from "react";
import { css } from "@emotion/react";
import { Typography } from "@material-ui/core";

const styles = {
  container: css`
    padding: 0px 20px 20px 20px;
  `,
};

interface EvaluationProps {
  evaluation: string;
}

export default function Evaluation(p: EvaluationProps) {
  return (
    <div css={styles.container}>
      <Typography align="center" variant="h6">
        Evaluation
      </Typography>
      <Typography align="center" variant="body1">
        {p.evaluation}
      </Typography>
    </div>
  );
}
