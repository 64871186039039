import React from "react";
import { css } from "@emotion/react";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const styles = {
  container: css`
    display: flex;
    align-items: center;
    padding: 5px;
    background-color: #dddddd
  `,
  backButton: css`
    color: white;
    background-color: rgb(139, 156, 7);
    border-radius: 100%;
    font-size: 20px;
    padding: 10px;
    margin-right: 10px;
  `,
}

interface NavigatorProps {
  parentLink: string;
  parent: string;
  current: string;
}

const Navigator = (p: NavigatorProps) => {
  const history = useHistory();

  const handleNavigateUp = () => {
    history.replace(p.parentLink)
  }
  
  return (<div css={styles.container}>
    <FontAwesomeIcon css={styles.backButton} icon={faArrowLeft} onClick={handleNavigateUp}/>
    <Breadcrumbs>
      <Typography css={css`color: #333333; font-weight: 400;`} onClick={handleNavigateUp}>{p.parent}</Typography>
      <Typography css={css`color: #000000; font-weight: 600;`}>{p.current}</Typography>
    </Breadcrumbs>
  </div>);
}

export default Navigator