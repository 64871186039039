import React, { useState } from "react";
import { css } from "@emotion/react";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import Footer from "../components/Footer";
import { useHistory } from "react-router";
import { useCreateSurvey } from "./queries/CreateSurvey";

const styles = {
  container: css`
    height: calc(100vh - 160px);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    gap: 1em 1em;
  `,
  card: css`
    max-width: 400px;
  `,
  access: css`
    display: flex;
  `,
};

interface CreateUserProps {}

export default function CreateSurvey(p: CreateUserProps) {
  const history = useHistory();
  const [createSurvey] = useCreateSurvey();
  const [internalName, setInternalName] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const valid = internalName.length >= 1 && name.length >= 1;

  const handleCreate = () => {
    createSurvey({
      variables: {
        internal_name: internalName,
        name: name,
        description: description || null,
      },
      refetchQueries: ["ListSurveys"],
    }).then((response) => {
      if (response.data?.insert_SUR_survey_one?.id != null) {
        history.replace(
          `/admin/surveys/${response.data?.insert_SUR_survey_one?.id}`
        );
      }
    });
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div css={styles.container}>
      <Card css={styles.card}>
        <CardContent>
          <Typography variant="h5">Create new survey</Typography>

          <TextField
            fullWidth
            required
            label="Internal Name"
            helperText="Identifies this survey within the admin panel"
            value={internalName}
            onChange={(e) => setInternalName(e.target.value)}
          />

          <Box marginTop={2}>
            <TextField
              fullWidth
              required
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              fullWidth
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={4}
            />
          </Box>
        </CardContent>
      </Card>
      <div />
      <Footer>
        <div>
          <Box component="span" m={1}>
            <Button variant="contained" onClick={handleBack}>
              Cancel
            </Button>
          </Box>
          <Box component="span">
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreate}
              disabled={!valid}
            >
              Create Survey
            </Button>
          </Box>
        </div>
      </Footer>
    </div>
  );
}
