import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const createMixQuery = gql`
mutation CreateMix($project_id: uuid!) {
  insert_MIX_mix_one(object: {name: "New Mix", project_id: $project_id, channels: "{0,0,0,0,0,0,0,0}"}) {
    id
  }
}
`;

export interface CreateMixData {
  insert_MIX_mix_one: {
    id: string;
  };
}

export interface CreateMixVariables {
  project_id: string;
}

export function useCreateMix(
  options?: MutationHookOptions<CreateMixData, CreateMixVariables> | undefined
) {
  return useMutation<CreateMixData, CreateMixVariables>(
    createMixQuery,
    options
  );
}
