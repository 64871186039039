const fallbackColor = "aaaaaa";

// Returns true if the string contains any characters besides hex
function containsIllegalCharacters(color: string): boolean {
  return /[^\da-fA-F]+/.test(color);
}

export function sanitizeColor(color: string): string {
  color = color.toLowerCase();
  color = color.replaceAll("#", "");
  if (color.length > 6) {
    color = color.substring(0, 6);
  }
  if (containsIllegalCharacters(color)) {
    color = fallbackColor;
  }
  return `#${color}`;
}
