import React from "react";
 import Overview from '../../Session/layout/overview'

interface ProjectOverviewProps {
  projectId: string;
}

const ProjectOverview = (p: ProjectOverviewProps) => {
  return (<Overview id={p.projectId}/>);
}

export default ProjectOverview