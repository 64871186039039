import { css } from "@emotion/react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import React from "react";
import { sanitizeColor } from "../../../util/SanitizeColor";
import QInputProps from "./QInputProps";
import { packValue, unpackValue } from "./util/selectionUtilities";

const styles = {
  colorSwabContainer: css`
    background-color: white;
    margin: 5px;
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    padding: 5px;
  `,
  colorSwabContainerChecked: css`
    padding: 0px !important;
  `,
  colorSwabBase: css`
    border-radius: 5px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-shadow: 0 0 3px #000;
  `,
};

interface ColorSwabProps {
  color: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

function ColorSwab(p: ColorSwabProps) {
  return (
    <div
      css={[
        styles.colorSwabContainer,
        p.checked ? styles.colorSwabContainerChecked : null,
      ]}
      onClick={() => p.onChange(!p.checked)}
    >
      <div css={[styles.colorSwabBase, css`background-color: ${sanitizeColor(p.color)}`]}>
        {p.checked ? <FontAwesomeIcon icon={faCheck}/> : null}
      </div>
    </div>
  );
}

export default function QSelectColor(p: QInputProps) {
  if (p.options == null || p.options.length === 0) {
    return <Typography>No options available.</Typography>;
  }

  const value = unpackValue(p.value, p.options);

  const handleChange = (index: number, checked: boolean) => {
    const newValue = [...value];
    newValue[index] = checked;
    p.onValueChange(packValue(newValue, p.options));
  };

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      {value.map((checked, index) => (
        <ColorSwab
          key={p.options![index]}
          color={p.options![index]}
          checked={checked}
          onChange={(checked) => handleChange(index, checked)}
        />
      ))}
    </Box>
  );
}
