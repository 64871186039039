import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const query = gql`
query GetSessionName($project_id: uuid!) {
  project_by_pk(id: $project_id) {
    name
  }
}
`;

export interface GetSessionNameVariables {
  project_id: string;
}

export interface GetSessionNameData {
  project_by_pk?: {
    name: string;
  };
}

export function useSessionName(
  options?:
    | QueryHookOptions<
        GetSessionNameData,
        GetSessionNameVariables
      >
    | undefined
) {
  return useQuery<
    GetSessionNameData,
    GetSessionNameVariables
  >(query, options);
}
