import React from "react";
import { css } from "@emotion/react";

const cssParent = css`
  display: flex;
  justify-content: flex-end;
`

interface FooterProps {
}

const Footer = (p: React.PropsWithChildren<FooterProps>) => {
  return (<div css={cssParent} {...p}>{p.children}</div>);
}

export default Footer;