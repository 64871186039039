import React, { useState } from "react";
import { css } from "@emotion/react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { gql, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import LoadableImage from "../components/LoadableImage";
import QRCode from "../components/QRCode";
import _, { sortBy } from "lodash";
import ApolloRouter from "../../../util/ApolloRouter";
import { Search } from "@material-ui/icons";
import { useListProjects } from "./queries/ListProjects";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

export type SortByColumn = "name" | "created_date" | "owner" | "description" | null;

export interface BetterSessionTableProps {
  sessionEntries: {
    id: string;
    name: string;
    owner_name: string;
    created_at?: Date | null;
    image?: string | null;
    description?: string | null;
    qr_codes: {
      id: string;
    }[];
  }[];
}

export function BetterSessionTable(p: BetterSessionTableProps) {
  const [sortByColumn, setSortByColumn] = useState<SortByColumn>("created_date");
  const [isAscending, setIsAscending] = useState(false);

  const [filterSession, setFilterSession] = useState("");
  const [filterOwner, setFilterOwner] = useState("");
  const [filterCreated, setFilterCreated] = useState("");
  const [filterDescription, setFilterDescription] = useState("");

  const history = useHistory();

  const handleCreateNewClicked = () => {
    history.push(`/admin/sessions/new`);
  };
  const handleSortingLabelClicked = (column: SortByColumn) => {
    if (sortByColumn === column) {
      setIsAscending(current => !current);
    } else {
      setSortByColumn(column);
      setIsAscending(true);
    }
  }

  // Compute rows to display based on sorts and filters
  let sortedEntries = p.sessionEntries.filter(session => {
    if (filterSession && !session.name.toLowerCase().includes(filterSession.toLowerCase())) {
      return false;
    }
    if (filterOwner && session.owner_name && !session.owner_name.toLowerCase().includes(filterOwner.toLowerCase())) {
      return false;
    }
    const createdString = session.created_at
      ? `${session.created_at.toLocaleDateString()} ${session.created_at.toLocaleTimeString()}`
      : "Not set";
    if (filterCreated && !createdString.toLowerCase().includes(filterCreated.toLowerCase())) {
      return false;
    }
    if (filterDescription && session.description && !session.description.toLowerCase().includes(filterDescription.toLowerCase())) {
      return false;
    }
    return true;
  })
  sortedEntries = _.sortBy(sortedEntries, (iter) => {
    switch (sortByColumn) {
      case "name":
        return iter.name;
      case "created_date":
        return iter.created_at;
      case "owner":
        return iter.owner_name;
      case "description":
        return iter.description;
    }
  });
  if (!isAscending) {
    sortedEntries = _.reverse(sortedEntries);
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width="130px">
            </TableCell>
            <TableCell width="200px">
              <SortingHeaderLabel active={sortByColumn === "name"} ascending={isAscending} onClick={() => handleSortingLabelClicked("name")}>
                Session
              </SortingHeaderLabel>
              <FilterInput filter={filterSession} onChange={setFilterSession}/>
            </TableCell>
            <TableCell width="100px">
            <SortingHeaderLabel active={sortByColumn === "owner"} ascending={isAscending} onClick={() => handleSortingLabelClicked("owner")}>
                Owner
              </SortingHeaderLabel>
              <FilterInput filter={filterOwner} onChange={setFilterOwner}/>

            </TableCell>

            <TableCell width="100px">
            <SortingHeaderLabel active={sortByColumn === "created_date"} ascending={isAscending} onClick={() => handleSortingLabelClicked("created_date")}>
                Created
              </SortingHeaderLabel>
              <FilterInput filter={filterCreated} onChange={setFilterCreated}/>

            </TableCell>
            <TableCell>
            <SortingHeaderLabel active={sortByColumn==="description"} ascending={isAscending} onClick={() => handleSortingLabelClicked("description")}>
                Description
              </SortingHeaderLabel>
              <FilterInput filter={filterDescription} onChange={setFilterDescription}/>
            </TableCell>
            <TableCell width="100px"></TableCell>
            <TableCell
              width="100px"
              css={css`
                min-width: 100px;
              `}
            >
              <Button
                css={css`
                  width: 100px;
                `}
                size="small"
                variant="contained"
                color="primary"
                onClick={handleCreateNewClicked}
              >
                Create
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedEntries.map((session) => (
            <TableRow
              key={session.id}
              onDoubleClick={() =>
                history.push(`/admin/sessions/${session.id}`)
              }
            >
              <TableCell>
                <LoadableImage
                  variant="icon"
                  url={session.image || undefined}
                />
              </TableCell>
              <TableCell>
                <span>{session.name}</span>
              </TableCell>
              <TableCell>
                {session.owner_name ? session.owner_name : "Not set"}
              </TableCell>
              <TableCell>
                {session.created_at
                  ? `${session.created_at.toLocaleDateString()} ${session.created_at.toLocaleTimeString()}`
                  : "Not set"}
              </TableCell>

              <TableCell>{session.description}</TableCell>
              <TableCell>
                <QRCode id={session.qr_codes.map((q) => q.id)} size={75} />
              </TableCell>
              <TableCell>
                <Button
                  css={css`
                    width: 100px;
                  `}
                  variant="contained"
                  size="small"
                  onClick={() => history.push(`/admin/sessions/${session.id}`)}
                >
                  Manage
                </Button>
                <Button
                  css={css`
                    width: 100px;
                  `}
                  variant="contained"
                  size="small"
                  onClick={() =>
                    history.push(`/admin/sessions/${session.id}/results`)
                  }
                >
                  Results
                </Button>
                <Button
                  css={css`
                    width: 100px;
                  `}
                  variant="contained"
                  size="small"
                  onClick={() =>
                    history.push(`/admin/sessions/copy/${session.id}`)
                  }
                >
                  Clone
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface FilterInputProps {
  filter: string;
  onChange: (newFilter: string) => void;
}

function FilterInput(p: FilterInputProps) {
  return (
    <TextField
    fullWidth
      value={p.filter}
      onChange={e => p.onChange(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
}

interface SortingHeaderLabelProps {
  children: React.ReactNode;
  active: boolean;
  ascending: boolean;
  onClick: () => void;
}

function SortingHeaderLabel(p: SortingHeaderLabelProps) {
  return <Box style={{fontWeight: p.active ? "bold" : undefined}} onClick={p.onClick}>
    <Box display="inline" style={{marginRight: "10px"}}>{p.children}</Box>
    {(p.active && p.ascending) ? <FontAwesomeIcon icon={faSortDown}/> : null}
    {(p.active && !p.ascending) ? <FontAwesomeIcon icon={faSortUp}/> : null}
  </Box>
}

const ProjectList = () => {
  const query = useListProjects({pollInterval: 2000});

  return (
    <ApolloRouter
      loadable={query}
      ready={(data) => (
        <BetterSessionTable
          sessionEntries={data.project.map((p) => ({
            id: p.id,
            name: p.name,
            image: p.image,
            description: p.description || "Not set",
            owner_name: p.owner?.display_name || "Not set",
            qr_codes: p.qr_codes,
            created_at: new Date(p.created_at),
          }))}
        />
      )}
    />
  );
};

export default ProjectList;
