import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const mutation = gql`
mutation CloneSession($existing_session_id: String!, $name: String!) {
  clone_session(session_id: $existing_session_id, name: $name) {
    new_session_id
  }
}
`;

export interface CloneSessionData {
  clone_session: {
    new_session_id: string
  };
}

export interface CloneSessionVariables {
  existing_session_id: string;
  name: string;
}

export function useCloneSession(
  options?:
    | MutationHookOptions<CloneSessionData, CloneSessionVariables>
    | undefined
) {
  return useMutation<CloneSessionData, CloneSessionVariables>(
    mutation,
    options
  );
}
