import { useLazyQuery } from "@apollo/client";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Label } from "../labels/label";
import {
  GetProjectQrCodes,
  GetProjectQrCodesData,
  GetProjectQrCodesVariables,
} from "./queries/queries";

interface PrintLabelsButtonProps {
  projectId: string;
}

export function PrintLabelsButton(p: PrintLabelsButtonProps) {
  const history = useHistory();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [quantity, setQuantity] = useState("1");
  const { load, data, loading, error } = useProjectQrCodes(p.projectId);

  const handleDialogClosed = () => {
    setDialogVisible(false);
  }
  const handleOnClick = () => {
    setDialogVisible(true);
  };
  const handleContinueClick = () => {
    load();
  }
  useEffect(() => {
    if (data) {
      let quantityCleaned = Math.max(1, Number(quantity))
      let multiplied: Label[] = [];
      for (let i = 0; i < Number(quantityCleaned); i++) {
        multiplied = [...multiplied, ...data];
      }
      console.log('effect')
      history.push("/admin/labels", multiplied);
    }
  }, [data]);

  return (
    <Fragment>
      <Dialog open={dialogVisible} onClose={handleDialogClosed}>
        <DialogTitle>Enter QR code quantity</DialogTitle>
        <DialogContent>
        <TextField
          id="standard-number"
          label="Quantity"
          type="number"
          value={quantity}
          onChange={e => setQuantity(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClosed}>Cancel</Button>
          <Button onClick={handleContinueClick}>Continue</Button>
        </DialogActions>
      </Dialog>
      <Button onClick={handleOnClick} disabled={loading} variant="contained">
        {loading ? "Loading" : error ? error.name : "Print Label Sheet"}
      </Button>
    </Fragment>
  );
}

export function useProjectQrCodes(projectId: string) {
  function getLabelsFromResponse(data: GetProjectQrCodesData): Label[] {
    let out = [] as Label[];
    out.push({
      type: "project",
      caption: data.project_by_pk.name,
      qrCode: data.project_by_pk.qr_codes_aggregate.aggregate.max.code,
    });
    data.project_by_pk.samples.forEach((sample) => {
      out.push({
        type: "sample",
        caption: sample.name,
        qrCode: sample.qr_codes_aggregate.aggregate.max.code,
      });
    });

    return out;
  }
  const [load, { data, loading, error }] = useLazyQuery<
    GetProjectQrCodesData,
    GetProjectQrCodesVariables
  >(GetProjectQrCodes, { variables: { project_id: projectId } });

  return {
    load,
    data: data ? getLabelsFromResponse(data) : undefined,
    loading,
    error,
  };
}
