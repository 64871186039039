import { gql, QueryHookOptions, useQuery } from "@apollo/client";

const LexiconDetails = gql`
query LexiconDetails($lexicon_id: uuid!) {
  lexicon_by_pk(id: $lexicon_id) {
    id
    name
    description
    image
    descriptors(order_by: {name: asc}) {
      id
      name
      description
      image
      color_name
      color_value
      evaluation_instruction
    }
  }
}
`;

interface LexiconDetailsVars {
  lexicon_id: string;
}

interface LexiconDetailsData {
  lexicon_by_pk: {
    id: string;
    name: string;
    description: string;
    image: string;
    descriptors: {
      id: string;
      name: string;
      description: string;
      image: string;
      color_name?: string;
      color_value?: string;
      evaluation_instruction?: string;
    }[];
  }
}


export default function useLexiconDetails(options?: QueryHookOptions<LexiconDetailsData, LexiconDetailsVars>) {
  return useQuery<LexiconDetailsData, LexiconDetailsVars>(LexiconDetails, options);
}