import _ from 'lodash';

export function isMultiSelectType(type: string): boolean {
  return type === "select_checkbox" || type === "select_color";
}

export function getSelectionCount(value: string | null): number {
  if (value == null) {
    return 0;
  }
  return value.split('|').length;
}

export function unpackValue(value: string | null, options: string[] | undefined) {
  let out = _.fill(Array(options?.length || 0), false);
  const split = value?.split("|") || [];
  split.forEach((selectedString) => {
    const foundIndex = options?.findIndex((o) => o === selectedString);
    if (foundIndex != null) {
      out[foundIndex] = true;
    }
  });
  return out;
}

export function packValue(selectedArr: boolean[], options: string[] | undefined): string | null {
  let out = "";
  selectedArr.forEach((s, index) => {
    if (s) {
      if (options != null) {
        out = `${out}|${options[index]}`;
      }
    }
  });
  if (out.length > 0) {
    return out.substr(1);
  } else {
    return null;
  }
}