import React from "react";
import { css } from "@emotion/react";
import { Box, Typography } from "@material-ui/core";
import Header from "../../../pages/mobile/layout/Header";
import qrImage from './scan_qr.svg';

const cssParent = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: white;
`;

const styles = {
  image: css`
    width: 100%;
    height: auto;
  `
}



export default function Welcome() {
  return (
    <div css={cssParent}>
      <Header />
      <Box padding={2}>
        <Typography align="center" variant="h3">
          Start a Session
        </Typography>
        <Typography align="center">
          Scan a QR code using your phone's camera app
        </Typography>
        <img alt="" src={qrImage} css={styles.image}/>
      </Box>
    </div>
  );
}
