import { gql } from "@apollo/client";

export const GetProjectById = gql`
query GetProjectById($id: uuid!) {
  project_by_pk(id: $id) {
    id
    name
    image
    description
    lexicon_id
    samples {
      id
      name
      description
      qr_codes {
        id
      }
    }
    qr_codes {
      id
    }
    br_ibis_number
    session_type
  }
}
`;

export interface GetProjectByIdVariables {
  id: string;
}

export interface GetProjectByIdResponse {
  project_by_pk: {
    id: string;
    name: string;
    description: string;
    image: string;
    lexicon_id: string;
    samples: {
      id: string;
      name: string;
      description: string;
      qr_codes: {
        id: string;
      }[];
    }[];
    qr_codes: {
      id: string;
    }[];
    br_ibis_number: string | null;
    session_type: string | null;
  };
}

export const GetProjectNameById = gql`
  query GetProjectNameById($id: uuid!) {
    project_by_pk(id: $id) {
      id
      name
    }
  }
`;

export interface GetProjectNameByIdVariables {
  id: string;
}

export interface GetProjectNameByIdResponse {
  project_by_pk: {
    id: string;
    name: string;
  };
}

export const CreateProject = gql`
  mutation CreateProject(
    $description: String!
    $lexicon_id: uuid
    $name: String!
    $image: String!
    $channels: [project_channel_insert_input!]!
  ) {
    insert_project_one(
      object: {
        name: $name
        image: $image
        description: $description
        lexicon_id: $lexicon_id
        qr_codes: { data: {} }
        channels: { data: $channels }
        survey: {
          data: { qr_codes: { data: {} }, name: $name, internal_name: $name }
        }
      }
    ) {
      id
      image
      lexicon_id
      name
      description
      qr_codes {
        id
      }
    }
  }
`;

export interface CreateProjectVariables {
  name: string;
  description: string;
  image: string;
  lexicon_id?: string;
  channels:
    | []
    | {
        name: string;
        channel_index: number;
        flow_value?: number;
        enabled?: boolean;
      }[];
}

export interface CreateProjectResponse {
  insert_project_one: {
    id: string;
    name: string;
    image: string;
    description: string;
    lexicon_id: string;
  };
}

export const UpdateProjectInfo = gql`
mutation UpdateProjectInfo($id: uuid!, $name: String!, $description: String!, $image: String!, $lexicon_id: uuid, $br_ibis_number: String = "", $session_type: String = "") {
  update_project_by_pk(pk_columns: {id: $id}, _set: {name: $name, description: $description, image: $image, lexicon_id: $lexicon_id, br_ibis_number: $br_ibis_number, session_type: $session_type}) {
    id
    name
    description
    image
    lexicon_id
    br_ibis_number
    session_type
  }
}
`;

export interface UpdateProjectInfoVariables {
  id: string;
  name: string;
  description: string;
  image: string;
  lexicon_id?: string;
  br_ibis_number?: string | null;
  session_type?: string | null;
}

export interface UpdateProjectInfoResponse {
  update_project_by_pk: {
    id: string;
    name: string;
    image: string;
    description: string;
    lexicon_id: string;
    br_ibis_number: string | null;
    session_type: string | null;
  };
}

export const CreateSample = gql`
  mutation CreateSample(
    $project_id: uuid!
    $name: String!
    $description: String!
  ) {
    insert_sample_one(
      object: {
        project_id: $project_id
        name: $name
        description: $description
        qr_codes: { data: {} }
      }
    ) {
      id
      project_id
      name
      description
    }
  }
`;

export interface CreateSampleVariables {
  project_id: string;
  name: string;
  description: string;
}

export interface CreateSampleResponse {
  insert_sample_one: {
    id: string;
    project_id: string;
    name: string;
    description: string;
  };
}

export const GetSampleDetails = gql`
  query GetSampleDetails($id: uuid!) {
    sample_by_pk(id: $id) {
      id
      name
      description
      qr_codes {
        id
      }
      descriptors(order_by: { name: asc }) {
        id
        name
      }
      evaluations(order_by: { author: { display_name: asc } }) {
        id
        author {
          id
          display_name
        }
        liking_score
        concept_fit_score
        comments
        descriptor_evaluations {
          id
          descriptor_id
          rating
        }
      }
    }
  }
`;

export interface GetSampleDetailsVariables {
  id: string;
}

export interface GetSampleDetailsResponse {
  sample_by_pk: {
    id: string;
    name: string;
    description: string;
    qr_codes: {
      id: string;
    }[];
    descriptors: {
      id: string;
      name: string;
    }[];
    evaluations: {
      id: string;
      author: {
        id: string;
        display_name: string;
      };
      liking_score?: number;
      concept_fit_score?: number;
      comments?: string;
      descriptor_evaluations: {
        id: string;
        descriptor_id: string;
        rating: number;
      }[];
    }[];
  };
}

export const UpdateSample = gql`
  mutation UpdateSample($id: uuid!, $name: String!, $description: String!) {
    update_sample_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, description: $description }
    ) {
      id
      name
      description
    }
  }
`;

export interface UpdateSampleVariables {
  id: string;
  name: string;
  description: string;
}

export interface UpdateSampleResponse {
  update_sample_by_pk: {
    id: string;
    name: string;
    description: string;
  };
}

export const PrintProjectLabels = gql`
  mutation PrintProjectLabels($project_id: uuid!) {
    print_project_labels(project_id: $project_id) {
      url
    }
  }
`;

export interface PrintProjectLabelsVariables {
  project_id: string;
}

export interface PrintProjectLabelsData {
  print_project_labels: {
    url: string;
  };
}

export const DeleteProjectMutation = gql`
  mutation DeleteProject($project_id: uuid!) {
    delete_project_by_pk(id: $project_id) {
      id
    }
  }
`;

export interface DeleteProjectVariables {
  project_id: string;
}

export interface DeleteProjectData {
  dlete_project_by_pk: {
    id: string;
  };
}

export const DeleteSampleMutation = gql`
  mutation DeleteSample($sample_id: uuid!) {
    delete_sample_by_pk(id: $sample_id) {
      id
    }
  }
`;

export interface DeleteSampleVariables {
  sample_id: string;
}

export interface DeleteSampleData {
  delete_sample_by_pk: {
    id: string;
  };
}

export const GetProjectQrCodes = gql`
  query GetProjectQrCodes($project_id: uuid!) {
    project_by_pk(id: $project_id) {
      id
      name
      qr_codes_aggregate {
        aggregate {
          max {
            code
          }
        }
      }
      samples {
        id
        name
        qr_codes_aggregate {
          aggregate {
            max {
              code
            }
          }
        }
      }
    }
  }
`;

export interface GetProjectQrCodesVariables {
  project_id: string;
}

export interface GetProjectQrCodesData {
  project_by_pk: {
    id: string;
    name: string;
    qr_codes_aggregate: {
      aggregate: {
        max: {
          code: string;
        };
      };
    };
    samples: {
      id: string;
      name: string;
      qr_codes_aggregate: {
        aggregate: {
          max: {
            code: string;
          };
        };
      };
    }[];
  };
}
