import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ApolloRouter from "../../../util/ApolloRouter";
import { useCloneSession } from "./queries/CloneProject";
import { useSessionName } from "./queries/GetSessionName";

export interface CloneSessionProps {
  sourceSessionId: string;
}

export function CloneSession(p: CloneSessionProps) {
  const sessionNameQuery = useSessionName({
    variables: { project_id: p.sourceSessionId },
  });
  const [cloneSession] = useCloneSession();
  const [isBusy, setIsBusy] = useState(false);
  const history = useHistory();

  const handleSubmit = (name: string) => {
    setIsBusy(true);
    console.log(
      `Beginning to clone session... name=${name} sourceSessionId=${p.sourceSessionId}`
    );

    console.log("Calling mutation");
    cloneSession({
      variables: {
        existing_session_id: p.sourceSessionId,
        name: name,
      },
    })
      .then((resp) => {
        if (resp.data) {
          history.push(
            `/admin/sessions/${resp.data.clone_session.new_session_id}`
          );
        }
      })
      .catch((e) => {
        setIsBusy(false);
        console.log("Failed to xlonw session");
        console.log(e);
      });
  };

  return (
    <Box display="flex" justifyContent="center">
      <ApolloRouter
        loadable={sessionNameQuery}
        ready={(data) => (
          <CloneSessionFormProps
            initialName={data.project_by_pk?.name || ""}
            onSubmit={handleSubmit}
            isBusy={isBusy}
          />
        )}
      />
    </Box>
  );
}

interface CloneSessionFormProps {
  isBusy?: boolean;
  initialName: string;
  onSubmit: (sessionName: string) => void;
}

function CloneSessionFormProps(p: CloneSessionFormProps) {
  const [sessionName, setSessionName] = useState(
    p.initialName ? `Copy of ${p.initialName}` : ""
  );

  const isBusy = p.isBusy || false;
  const maySubmit = !isBusy && sessionName.length > 0;

  const handleSubmit = () => {
    p.onSubmit(sessionName);
  };
  return (
    <Box component={Card} width="fit-content">
      <CardContent>
        <Typography variant="h4">Copy Session</Typography>
        <Box marginTop="10px">
          <TextField
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            label="New session name (Required)"
            value={sessionName}
            onChange={(e) => setSessionName(e.target.value)}
            disabled={isBusy}
          />
        </Box>
        <Box marginTop="20px" width="550px">
          <Typography variant="body1">
            Session data, channels, mixes, and the survey will be copied from{" "}
            <b>{p.initialName}</b>. Mix feedback and survey responses{" "}
            <i>will not</i> be copied.
          </Typography>
        </Box>
      </CardContent>
      <CardActions style={{ justifyContent: "flex-end" }}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={!maySubmit}
        >
          Clone Session
        </Button>
      </CardActions>
    </Box>
  );
}
