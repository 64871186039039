import { css } from "@emotion/react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import React from "react";

const styles = {
  container: css`
    color: #ff2222;
  `
}

export interface ErrorNoticeProps {
  errorMessage: string;
}

export default function ErrorNotice(p: ErrorNoticeProps) {
  return <Box css={styles.container} display="flex" flexDirection="row" paddingTop={2}>
   <FontAwesomeIcon icon={faInfoCircle} fixedWidth size="lg"/> 
   <Typography>{p.errorMessage}</Typography>
  </Box>
}