import { css } from "@emotion/react";
import { Box, Button, CardContent, styled, Typography } from "@material-ui/core";
import React, { useState } from "react";
import SurveyCard from "../components/SurveyCard";
import {
  getSelectionCount,
  isMultiSelectType,
} from "./components/inputs/util/selectionUtilities";
import QuestionCard, {
  QuestionCardConstantProps,
} from "./components/QuestionCard";

function getErrorMessage(
  p: QuestionCardConstantProps,
  value: string | null
): string | null {
  if (p.required && value == null) {
    return "This question is required.";
  }
  if (
    isMultiSelectType(p.type) &&
    p.validationMode != null &&
    p.validationCount != null &&
    value != null
  ) {
    const selectionCount = getSelectionCount(value);
    switch (p.validationMode) {
      case "at_least":
        if (selectionCount < p.validationCount) {
          return (
            p.validationErrorMessage ||
            `You must make at least ${p.validationCount} selections.`
          );
        }
        break;
      case "at_most":
        if (selectionCount > p.validationCount) {
          return (
            p.validationErrorMessage ||
            `You must make at most ${p.validationCount} selections.`
          );
        }
        break;
      case "exactly":
        if (selectionCount !== p.validationCount) {
          return (
            p.validationErrorMessage ||
            `You must make exactly ${p.validationCount} selections.`
          );
        }
        break;
    }
  }

  return null;
}

export interface Answers {
  [questionId: string]: string | null;
}

export interface SurveyFormDataProps {
  name: string;
  description: string;
  questions: QuestionCardConstantProps[];
}

export interface SurveyFormProps extends SurveyFormDataProps {
  onSubmit: (answers: Answers) => void;
  onError: () => void;
}

export default function SurveyForm(p: SurveyFormProps) {
  const [answers, setAnswers] = useState<Answers>({});
  const [wasSubmittedWithErrors, setWasSubmittedWithErrors] =
    useState<boolean>(false);

  const hasAnyRequiredFields = p.questions.find((q) => q.required) != null;
  const handleValueChanged = (questionId: string, value: string | null) => {
    const newAnswers = { ...answers };
    newAnswers[questionId] = value;
    setAnswers(newAnswers);
  };
  const handleSubmit = () => {
    // Loop through each question, check if it has any errors.
    for (let q of p.questions) {
      if (getErrorMessage(q, answers[q.id]) != null) {
        setWasSubmittedWithErrors(true);
        p.onError();
        return;
      }
    }

    p.onSubmit(answers);
  };

  const BlueButton = styled(Button)({
    background: '#00265f',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  });

  return (
    <div
      css={css`
        width: 100%;
        max-width: 500px;
      `}
    >
      <SurveyCard topStripe>
        <CardContent>
          <Typography variant="h4" >{p.name}</Typography>
          <Typography variant="body1">{p.description}</Typography>
          {hasAnyRequiredFields ? (
            <Typography
              css={css`
                color: #ff3333;
              `}
            >
              * Required
            </Typography>
          ) : null}
        </CardContent>
      </SurveyCard>
      {p.questions.map((questionProps) => {
        const value = answers[questionProps.id];
        const errorMessage = wasSubmittedWithErrors
          ? getErrorMessage(questionProps, value)
          : null; // Only show error nags if user has tried to submit
        return (
          <Box key={questionProps.id} marginTop={1} width="100%">
            <QuestionCard
              value={value}
              errorMessage={errorMessage}
              onValueChanged={(newValue) =>
                handleValueChanged(questionProps.id, newValue)
              }
              {...questionProps}
            />
          </Box>
        );
      })}
      <Box marginTop={1}>
        <BlueButton
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Submit Response
        </BlueButton>
      </Box>
    </div>
  );
}
