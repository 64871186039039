import React from "react";
import { Tabs as MUITabs, Tab as MUITab } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAirFreshener, faBlender, faFileCsv, faInfo, faPoll, faSlidersH, faVoteYea } from "@fortawesome/free-solid-svg-icons";
import { css } from "@emotion/react";

interface TabsProps {
  value?: TabPage;
  onChange?: (event: React.ChangeEvent<{}>, value: TabPage) => void;
}

export type TabPage = "overview" | "report";

export default function Tabs(p: TabsProps) {
  return (
    <MUITabs
      css={css`flex-grow: 1;`}
      value={p.value}
      indicatorColor="primary"
      textColor="primary"
      onChange={p.onChange}
    >
      <MUITab label="Overview" value={"overview"} icon={<FontAwesomeIcon icon={faInfo}/>}/>
      <MUITab label="Channels" value={"channels"} icon={<FontAwesomeIcon icon={faSlidersH}/>}/>
      <MUITab label="Mixes" value={"mixes"} icon={<FontAwesomeIcon icon={faBlender}/>}/>
      <MUITab label="Survey" value={"survey"} icon={<FontAwesomeIcon icon={faVoteYea}/>}/>
      <MUITab label="Results" value={"results"} icon={<FontAwesomeIcon icon={faFileCsv}/>}/>
    </MUITabs>
  );
}
