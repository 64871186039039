import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const query = gql`
  query ListProjects {
    project {
      id
      name
      image
      description
      created_at
      qr_codes {
        id
      }
      owner {
        display_name
      }
    }
  }
`;

export interface ListProjectsVariables {}

export interface ListProjectsData {
  project: {
    id: string;
    name: string;
    image: string | null;
    description: string | null;
    created_at: string;
    qr_codes: { id: string }[];
    owner: {
      display_name: string;
    } | null;
  }[];
}

export function useListProjects(
  options?:
    | QueryHookOptions<ListProjectsData, ListProjectsVariables>
    | undefined
) {
  return useQuery<ListProjectsData, ListProjectsVariables>(query, options);
}
