import React from "react";
import { css } from "@emotion/react";
import ReactWordcloud, { MinMaxPair, Word } from "react-wordcloud";
import DeferredComponent from "./DeferredComponent";

const styles = {
  container: css`
    display: flex;
    justify-content: center;
  `,
};

export interface WordCloudWord extends Word {}

export interface WordCloudProps {
  words: WordCloudWord[];
  size?: MinMaxPair;
}

const MemoizedWordcloud = React.memo(ReactWordcloud);

export default function WordCloud(p: WordCloudProps) {
  return (
    <DeferredComponent>
      <MemoizedWordcloud
        words={p.words}
        maxWords={15}
        options={{
          enableTooltip: false,
          rotations: 1,
          rotationAngles: [0, 0],
          fontSizes: [20, 40],
          colors: ["#1d2600", "#3c5505", "#678400", "#99ad00", "#b8d730"],
        }}
        size={p.size}
        minSize={[100, 100]}
        css={styles.container}
      />
    </DeferredComponent>
  );
}
