import { gql, QueryHookOptions, useQuery } from "@apollo/client";

const LexiconName = gql`
  query LexiconName($lexicon_id: uuid!) {
    lexicon_by_pk(id: $lexicon_id) {
      id
      name
    }
  }
`;

interface LexiconNameVars {
  lexicon_id: string;
}

interface LexiconNameData {
  lexicon_by_pk: {
    id: string;
    name: string;
  };
}

export default function useLexiconName(
  lexiconId: string,
  options?: QueryHookOptions<LexiconNameData, LexiconNameVars>
) {
  return useQuery<LexiconNameData, LexiconNameVars>(LexiconName, {
    variables: { lexicon_id: lexiconId, ...options?.variables },
    ...options,
  });
}
