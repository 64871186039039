import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const mutation = gql`
mutation CreateNewProject($name: String!, $session_type: String, $channels: [project_channel_insert_input!]!, $owner_id: uuid!) {
  insert_project_one(object: {survey: {data: {qr_codes: {data: {}}, name: $name, internal_name: $name}}, name: $name, qr_codes: {data: {}}, session_type: $session_type, channels: {data: $channels}, owner_id: $owner_id, image: "", description: ""}) {
    id
  }
}
`;

export interface CreateNewProjectData {
  insert_project_one: {
    id: string;
    session_type?: string;
  };
}

export interface CreateNewProjectVariables {
  name: string;
  session_type?: string;
  owner_id: string;
  channels: {
    name: string;
    channel_index: number;
    flow_value?: number;
    enabled?: boolean;
  }[];
}

export function useCreateNewProject(
  options?:
    | MutationHookOptions<CreateNewProjectData, CreateNewProjectVariables>
    | undefined
) {
  return useMutation<CreateNewProjectData, CreateNewProjectVariables>(
    mutation,
    options
  );
}
