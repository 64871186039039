import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

const query = gql`
query GetProjectMixRatingsCsv($project_id: uuid!) {
  MIX_rating_csv(where: {project_id: {_eq: $project_id}}) {
    project_id
    csv
  }
}
`;

export interface GetProjectMixRatingsCsvVariables {
  project_id: string;
}

export interface GetProjectMixRatingsCsvData {
  MIX_rating_csv?: {
    project_id: string;
    csv: string;
  }[];
}

export function useProjectMixRatingsCsv(
  options?:
    | QueryHookOptions<
        GetProjectMixRatingsCsvData,
        GetProjectMixRatingsCsvVariables
      >
    | undefined
) {
  return useQuery<
    GetProjectMixRatingsCsvData,
    GetProjectMixRatingsCsvVariables
  >(query, options);
}
