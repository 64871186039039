import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const query = gql`
query ListSurveys {
  SUR_survey(where: {_not: {project: {}}}) {
    id
    internal_name
    name
    responses_aggregate {
      aggregate {
        count
      }
    }
  }
}
`;

export interface ListUnlinkedSurveysData {
  SUR_survey: {
    id: string;
    internal_name: string;
    name: string;
    responses_aggregate: {
      aggregate: {
        count: number;
      }
    }
  }[]
}

export interface ListUnlinkedSurveysVariables {
}

export function useListUnlinkedSurveysQuery(
  options?:
    | QueryHookOptions<
        ListUnlinkedSurveysData,
        ListUnlinkedSurveysVariables
      >
    | undefined
) {
  return useQuery<
    ListUnlinkedSurveysData,
    ListUnlinkedSurveysVariables
  >(query, options);
}
