import {
  faBrain,
  faClipboardList,
  faFont,
  faGraduationCap,
  faLightbulb,
  faPrint,
  faVials,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React from "react";

export type SessionType =
  | "concept_development"
  | "language_training"
  | "consumer_test"
  | null;

export interface SessionTypePickerProps {
  sessionType: SessionType;
  onChange: (newSessionType: SessionType) => void;
}

export function SessionTypePicker(p: SessionTypePickerProps) {

  const handleChange = (_: any, value: string | null) => {
    if (value === 'unset') {
      value = null;
    }
    p.onChange(value as SessionType)
  }

  return (
    <Box>
      <ToggleButtonGroup color="primary" value={p.sessionType || "unset"} onChange={handleChange} exclusive>
        <ToggleButton value="concept_development">
          <Box display="flex" alignItems="center">
            <FontAwesomeIcon icon={faLightbulb} size="2x" />
            <span style={{ width: "105px", marginLeft: "10px" }}>
              Concept Development
            </span>
          </Box>
        </ToggleButton>
        <ToggleButton value="language_training">
          <Box display="flex" alignItems="center">
            <FontAwesomeIcon icon={faGraduationCap} size="2x" />
            <span style={{ width: "85px", marginLeft: "10px" }}>
              Language Training
            </span>
          </Box>
        </ToggleButton>
        <ToggleButton value="consumer_test">
          <Box display="flex" alignItems="center">
            <FontAwesomeIcon icon={faClipboardList} size="2x" />
            <span style={{ width: "85px", marginLeft: "10px" }}>
              Consumer Test
            </span>
          </Box>
        </ToggleButton>
        <ToggleButton value="unset">Internal</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}
