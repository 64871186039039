import { Box } from "@material-ui/core";
import React from "react";
import ApolloRouter from "../../../../util/ApolloRouter";
import { useProjectSurveyId } from "../../../queries/GetProjectSurveyId";
import EditSurvey from "../../surveys/tabs/EditSurvey";

export interface SurveyTabProps {
  sessionId: string;
}

export function SurveyTab(p: SurveyTabProps) {
  const query = useProjectSurveyId({ variables: { id: p.sessionId } });

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <ApolloRouter
        loadable={query}
        ready={(data) => (
          <EditSurvey surveyId={data.project_by_pk!.survey_id} />
        )}
      />
    </Box>
  );
}
