import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const INSERT_QUESTION_MUTATION = gql`
  mutation InsertQuestion(
    $id: uuid!
    $survey_id: uuid!
    $name: String!
    $type: String!
    $sort_position: Int!
    $required: Boolean = false
    $description: String = null
  ) {
    insert_SUR_survey_question_one(
      object: {
        id: $id
        survey_id: $survey_id
        name: $name
        type: $type
        sort_position: $sort_position
        required: $required
        description: $description
      }
    ) {
      id
      name
      description
      required
      sort_position
      survey_id
      type
    }
  }
`;

export interface InsertQuestionData {
  insert_SUR_survey_question_one: {
    id: string;
    name: string;
    description: string | null;
    required: boolean;
    sort_position: number;
    survey_id: string;
    type: string;
  }
}

export interface InsertQuestionVariables {
  id: string;
  survey_id: string;
  name: string;
  sort_position: number;
  type: string;
  required?: boolean | null;
  description?: boolean | null;
}

export function useInsertQuestionMutation(
  options?:
    | MutationHookOptions<InsertQuestionData, InsertQuestionVariables>
    | undefined
) {
  return useMutation<InsertQuestionData, InsertQuestionVariables>(
    INSERT_QUESTION_MUTATION,
    options
  );
}
