import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const SUBMIT_SURVEY_RESPONSE_MUTATION = gql`
  mutation SubmitSurveyResponse(
    $survey_id: uuid!
    $question_responses: [SUR_response_question_insert_input!]!
  ) {
    insert_SUR_response_one(
      object: {
        survey_id: $survey_id
        question_responses: { data: $question_responses }
      }
    ) {
      id
    }
  }
`;

export interface SubmitSurveyResponseData {
  insert_SUR_response_one: {
    id: string;
  };
}

export interface SubmitSurveyResponseVariables {
  survey_id: string;
  question_responses: {
    question_id: string;
    value: string | null;
  }[]
}

export function useSubmitSurveyResponseMutation(
  options?:
    | MutationHookOptions<SubmitSurveyResponseData, SubmitSurveyResponseVariables>
    | undefined
) {
  return useMutation<SubmitSurveyResponseData, SubmitSurveyResponseVariables>(
    SUBMIT_SURVEY_RESPONSE_MUTATION,
    options
  );
}
