import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ProjectList from "./pages/projects/SessionList";
import UserList from "./pages/users/UserList";
import { SessionPage } from "./pages/projects/SessionPage";
import CreateUser from "./pages/users/CreateUser";
import SurveyList from "./pages/surveys/SurveyList";
import CreateSurvey from "./pages/surveys/CreateSurvey";
import ManageSurveyPage from "./pages/surveys/ManageSurveyPage";
import { NewSession } from "./pages/projects/NewSession";
import { CloneSession } from "./pages/projects/CloneSession";
import { PrintLabels } from "./pages/labels/PrintLabels";
import { Label } from "./pages/labels/label";

export default function Routes() {
  return (
    <Switch>
      <Route
        path="/admin/sessions/copy/:sessionId"
        render={(p) => <CloneSession sourceSessionId={p.match.params["sessionId"]}/>}
      />
      <Route path="/admin/sessions/new" render={(p) => <NewSession />} />
      <Route
        path="/admin/sessions/:sessionId"
        render={(p) => <SessionPage sessionId={p.match.params["sessionId"]} />}
      />
      {/* <Route path="/admin/export" render={(p) => <Export />} /> */}
      <Route path="/admin/sessions" render={(p) => <ProjectList />} />
      <Route path="/admin/users/new" render={(p) => <CreateUser />} />
      <Route path="/admin/users" render={(p) => <UserList />} />
      {/* <Route path="/admin/mixes" render={(p) => <MixList />} /> */}
      <Route path="/admin/surveys/new" render={(p) => <CreateSurvey />} />
      <Route
        path="/admin/surveys/:surveyId"
        render={(p) => (
          <ManageSurveyPage surveyId={p.match.params["surveyId"]} />
        )}
      />
      <Route path="/admin/surveys" render={(p) => <SurveyList />} />
      <Route
        path="/admin/labels"
        render={(p) => <PrintLabels labels={p.location.state as Label[]} />}
      />
      <Route path="/admin" render={(p) => <Redirect to="/admin/sessions/" />} />
    </Switch>
  );
}
