import React, { useState } from "react";
import { css } from "@emotion/react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import LoadableImage from "../components/LoadableImage";
import { useHistory } from "react-router-dom";
import QRCode from "../components/QRCode";
import ImageUpload from "../components/ImageUpload";
import Footer from "../components/Footer";
import {
  GetProjectById,
  GetProjectByIdResponse,
  GetProjectByIdVariables,
  UpdateProjectInfo,
  UpdateProjectInfoResponse,
  UpdateProjectInfoVariables,
} from "./queries/queries";
import { Skeleton } from "@material-ui/lab";
import DeleteProject from "./DeleteProject";
import { PrintLabelsButton } from "./ProjectQrCodePrinter";

const styles = {
  container: css`
    // height: calc(100vh - 160px);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 325px 1fr auto;
    gap: 1em 1em;
    grid-template-areas:
      "main"
      "samples"
      "footer";
  `,
  main: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 1em 1em;
    grid-template-areas: "name image lexicon qr";
    grid-area: main;
  `,
};

interface EditProjectLoadedProps {
  id: string;
  name: string;
  briefNumber?: string | null;
  sessionType?: string | null;
  description: string;
  image: string;
  qrCodes: string[];
}

const EditProjectLoaded = (p: EditProjectLoadedProps) => {
  const history = useHistory();
  const [name, setName] = useState(p.name || "");
  const [briefNumber, setBriefNumber] = useState(p.briefNumber || "");
  const [description, setDescription] = useState(p.description || "");
  const [sessionType, setSessionType] = useState(p.sessionType || null);
  const [image, setImage] = useState(p.image || "");
  const [updateProject] = useMutation<
    UpdateProjectInfoResponse,
    UpdateProjectInfoVariables
  >(UpdateProjectInfo, { refetchQueries: ["GetProjects"] });
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const handleCreateProjectClicked = () => {
    updateProject({
      variables: {
        id: p.id,
        name: name,
        image: image,
        description: description,
        br_ibis_number: briefNumber,
        session_type: sessionType,
      },
    }).then((resp) => {
      setSnackBarOpen(true);
      // history.replace(`/admin/sessions`);
    });
  };
  const handleSessionTypeChanged = (newValue: string | null) => {
    if (newValue === "unset") {
      newValue = null;
    }
    setSessionType(newValue);
  };
  const handleCloseSnackBar = () => {
    setSnackBarOpen(false);
  };

  return (
    <div css={styles.container}>
      <Card
        css={css`
          grid-area: main;
        `}
      >
        <CardContent css={styles.main}>
          <div>
            <Typography align="center" variant="h5">
              Session
            </Typography>
            <TextField
              fullWidth
              label="Session name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              fullWidth
              label="Brief or IBIS #"
              value={briefNumber}
              onChange={(e) => setBriefNumber(e.target.value)}
            />
            <FormControl fullWidth>
              <InputLabel>Session Type</InputLabel>
              <Select
                fullWidth
                value={sessionType || "unset"}
                onChange={(e) =>
                  handleSessionTypeChanged(e.target.value as string)
                }
              >
                <MenuItem value="concept_development">
                  Concept Development
                </MenuItem>
                <MenuItem value="language_training">Languge Training</MenuItem>
                <MenuItem value="consumer_test">Consumer Test</MenuItem>
                <MenuItem value="unset">Internal</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              rows={4}
              multiline
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div>
            <InputLabel>Image</InputLabel>
            <LoadableImage
              url={image}
              css={css`
                height: 125px;
              `}
            />
            <ImageUpload onSuccess={setImage} />
          </div>
          {/* <div>
            <InputLabel>Lexicon</InputLabel>
            <LexiconSelect id={lexiconId} onSelect={setLexiconId}/>
          </div> */}
          <div>
            <InputLabel>QR Code</InputLabel>
            <QRCode
              id={p.qrCodes}
              css={css`
                height: 175px;
                width: 175px;
              `}
            />
            <PrintLabelsButton projectId={p.id} />
          </div>
        </CardContent>
      </Card>
      <Footer>
        <div>
            <Box component="span" m={1}>
              <DeleteProject projectId={p.id} />
            </Box>
          <Box component="span" m={1}>
            <Button
              variant="contained"
              onClick={() => {
                history.push(`/admin/sessions`);
              }}
            >
              Cancel
            </Button>
          </Box>
          <Box component="span">
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateProjectClicked}
            >
              Save Changes
            </Button>
          </Box>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={snackBarOpen}
            autoHideDuration={3000}
            onClose={handleCloseSnackBar}
            message="Session was saved successfully"
          />
        </div>
      </Footer>
    </div>
  );
};

interface EditProjectProps {
  id: string;
}

const EditProject = (p: EditProjectProps) => {
  const isNew = !p.id || p.id === "new";
  const { data } = useQuery<GetProjectByIdResponse, GetProjectByIdVariables>(
    GetProjectById,
    { variables: { id: p.id! }, skip: isNew }
  );

  if (isNew) {
    return (
      <EditProjectLoaded
        id="new"
        name="New Project"
        briefNumber=""
        description="Description for a new project"
        image=""
        qrCodes={[]}
      />
    );
  } else if (data) {
    return (
      <EditProjectLoaded
        id={data.project_by_pk.id}
        name={data.project_by_pk.name}
        briefNumber={data.project_by_pk.br_ibis_number}
        sessionType={data.project_by_pk.session_type}
        description={data.project_by_pk.description}
        image={data.project_by_pk.image}
        qrCodes={data.project_by_pk.qr_codes.map((code) => code.id)}
      />
    );
  } else {
    return (
      <div css={styles.container}>
        <Skeleton
          css={css`
            grid-area: main;
            background-color: red;
            height: unset !important;
          `}
          variant="rect"
        />
        <Skeleton
          css={css`
            grid-area: preview;
            background-color: red;
            height: unset !important;
          `}
          variant="rect"
        />
        <Skeleton
          css={css`
            grid-area: descriptors;
            background-color: red;
            height: unset !important;
          `}
          variant="rect"
        />
      </div>
    );
  }
};

export default EditProject;
