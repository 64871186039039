import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const createMixQuery = gql`
mutation DeleteMixById($mix_id: uuid!) {
  delete_MIX_mix_by_pk(id: $mix_id) {
    id
  }
}
`;

export interface CreateMixData {
  delete_MIX_mix_by_pk: {
    id: string;
  };
}

export interface CreateMixVariables {
  mix_id: string;
}

export function useDeleteMixById(
  options?: MutationHookOptions<CreateMixData, CreateMixVariables> | undefined
) {
  return useMutation<CreateMixData, CreateMixVariables>(
    createMixQuery,
    options
  );
}
